import { useCallback } from 'react'

import { Wrapper, TextField, IconSearch, IconEmpty, ButtonFilter } from './Styled'
import { report } from 'hooks/useAnalytics'

/** 元件 - 搜尋片名的Input框 */
export default function SearchField({
  placeholder = '输入关键字搜寻',
  disabled,
  onSearch,
  onRemoveContent,

  value,
  onChange,

  ...otherProps
}) {
  const onSubmit = useCallback(
    (e) => {
      report({
        SerialNumber: 4,
        Event: 'search_click',
        Trigger: '點擊搜尋',
        Parameters: 'content',
        Value: 'search',
      })
      e?.preventDefault()
      onSearch?.(value)
    },
    [onSearch, value]
  )

  const onWrappedRemoveContent = useCallback(() => {
    report({
      SerialNumber: 3,
      Event: 'search_click',
      Trigger: '點擊清除搜尋',
      Parameters: 'content',
      Value: 'clear_search',
    })
    onRemoveContent()
  }, [onRemoveContent])

  const onWrappedChange = useCallback(
    (ev) => {
      report({
        SerialNumber: 3,
        Event: 'search_click',
        Trigger: '輸入搜尋',
        Parameters: 'search_query',
        Value: ev.currentTarget.value,
      })
      onChange?.(ev.currentTarget.value)
    },
    [onChange]
  )

  const onEnter = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        onSubmit()
        e.preventDefault()
      }
    },
    [onSubmit]
  )

  return (
    <>
      <Wrapper {...otherProps} onSubmit={onSubmit}>
        <TextField
          type={'text'}
          placeholder={placeholder}
          value={value}
          onChange={onWrappedChange}
          onKeyDown={onEnter}
          onClick={() => {
            report({
              SerialNumber: 2,
              Event: 'search_click',
              Trigger: '點擊搜尋框',
              Parameters: 'content',
              Value: 'search_box',
            })
          }}
        />
        {value ? <IconEmpty onClick={onWrappedRemoveContent} /> : <IconSearch />}
      </Wrapper>
      <ButtonFilter onClick={onSubmit}>搜索</ButtonFilter>
    </>
  )
}
