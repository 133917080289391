import { useState, useRef, useImperativeHandle, forwardRef } from 'react'
import { Wrapper, CharBlockRow, CharBlock, Icon } from './Styled'

export default forwardRef(function InputRow({ label, onInput, onClick, onFinish, icon }, ref) {
  const [code, setCode] = useState([].slice(0, 4))
  const inputRef = useRef([].slice(0, 4))

  const inputs = [1, 2, 3, 4].map((m, i) => (
    <CharBlock
      key={i}
      ref={(el) => (inputRef.current[i] = el)}
      type="text"
      maxLength={1}
      value={code[i]}
      pattern="[0-9]*"
      inputMode="numeric"
      onKeyUp={(e) => handleInput(i, e.key)}
      onClick={onClick}
    ></CharBlock>
  ))

  useImperativeHandle(ref, () => ({
    focus() {
      if (inputRef.current[0] && inputRef.current[0].focus) {
        inputRef.current[0].focus()
      }
    },
  }))

  function handleInput(index, value) {
    const newDigit = parseInt(value)
    const nextCode = [...code]
    if (value === 'Backspace' || value === 'Delete') {
      value = ''
      nextCode[index] = value
      setCode(nextCode)
      onInput(nextCode.join(''))
      return
    } else if (isNaN(newDigit) || newDigit < 0 || newDigit > 9) {
      return
    }
    nextCode[index] = value
    setCode(nextCode)
    onInput(nextCode.join(''))
    if (index < inputs.length - 1) {
      inputRef.current[index + 1].focus && inputRef.current[index + 1].focus()
    } else if (typeof onFinish == 'function') {
      onFinish()
    }
  }

  return (
    <Wrapper>
      <div>
        <Icon src={icon} alt="" />
        <span>{label}</span>
      </div>
      <div>
        <CharBlockRow>{inputs}</CharBlockRow>
      </div>
    </Wrapper>
  )
})
