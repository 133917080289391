import type videoJs from 'video.js'
import type Component from 'video.js/dist/types/component'
type VideoJs = typeof videoJs

const MAX_DOUBLE_CLICK_INTERVAL_MS = 300
const LATENCY_AFTER_DOUBLE_CLICK_BEFORE_CLICK = 500

let installed = false

export interface IMobileControlLayer extends Component {
  setEndScreenVisibility(visible: boolean): void
}

export const addMobileControlLayer = (Video: VideoJs) => {
  if (installed) {
    return
  }

  installed = true

  const getRootShim = (card: MobileControlLayer) => {
    return (card as any).root as HTMLDivElement
  }

  const setRootShim = (card: MobileControlLayer, button: HTMLDivElement) => {
    return ((card as any).root = button)
  }
  const Component = Video.getComponent('Component')
  class MobileControlLayer extends Component {
    clickTimer: null | number = null
    lastDoubleClick = 0
    pointerdownTimeout: null | number = null
    speedUpMode: boolean = true
    windowPointerup: null | number = 0
    createEl() {
      /** @type {HTMLDivElement} */
      var el = Component.prototype.createEl.call(this, 'div', {
        className: 'vjs-mobile-control-layer',
      }) as HTMLDivElement

      var playIcon = Component.prototype.createEl.call(this, 'img', {
        className: 'vjs-mobile-control-layer-play',
        src: `${process.env.PUBLIC_URL}/short-player-ui/play.svg`,
      })

      el.appendChild(playIcon)

      const player = this.player()

      el.addEventListener('pointerup', (ev) => {
        if (this.pointerdownTimeout != null) {
          this.clearTimeout(this.pointerdownTimeout)
          this.pointerdownTimeout = null
        }
        if (this.speedUpMode) {
          this.setSpeedUpMode(false)
          return
        }

        if (this.clickTimer == null) {
          if (this.lastDoubleClick + LATENCY_AFTER_DOUBLE_CLICK_BEFORE_CLICK < Date.now()) {
            this.clickTimer = this.setTimeout(() => {
              // FIXME: some really unsafe code here
              player
                .tech(process.env.NODE_ENV !== 'development')
                .el()
                .dispatchEvent(new MouseEvent('click', ev))
              this.clickTimer = null
            }, MAX_DOUBLE_CLICK_INTERVAL_MS)
          } else {
            // just make it double click again, we don't want to accident pause video
            // FIXME: some really unsafe code here
            player
              .tech(process.env.NODE_ENV !== 'development')
              .el()
              .dispatchEvent(new MouseEvent('dblclick', ev))
          }

          // return
        } else {
          this.clearTimeout(this.clickTimer)
          this.clickTimer = null
          // FIXME: some really unsafe code here
          player
            .tech(process.env.NODE_ENV !== 'development')
            .el()
            .dispatchEvent(new MouseEvent('dblclick', ev))
          this.lastDoubleClick = Date.now()
        }
      })

      el.addEventListener('pointerdown', (ev) => {
        if (this.pointerdownTimeout != null) {
          this.clearTimeout(this.pointerdownTimeout)
        }

        const pointerupEvent = () => {
          document.removeEventListener('pointerup', pointerupEvent)
          if (this.speedUpMode) {
            this.setSpeedUpMode(false)
          }
          if (this.pointerdownTimeout) {
            this.clearTimeout(this.pointerdownTimeout)
            this.pointerdownTimeout = null
          }
        }
        document.addEventListener('pointerup', pointerupEvent)

        this.pointerdownTimeout = this.setTimeout(() => {
          this.setSpeedUpMode(true)
          player
            .tech(process.env.NODE_ENV !== 'development')
            .el()
            .dispatchEvent(new MouseEvent('longclick', ev))
        }, 1000)
      })

      setRootShim(this, el)

      return el
    }

    setEndScreenVisibility(visible: boolean) {
      if (visible) {
        getRootShim(this).classList.remove('vjs-mobile-control-layer__hide-on-end')
      } else {
        getRootShim(this).classList.add('vjs-mobile-control-layer__hide-on-end')
      }
    }

    setSpeedUpMode(enable: boolean) {
      const seek = this.player().getDescendant('SeekCard') as Component & {
        setSpeedUpMode: (v: boolean) => {}
      }
      let animatingFlag = this.el()?.closest('.virtual-page-animating') as HTMLElement | null
      // 已經開始進行上下換頁，影片不要快轉
      if (animatingFlag) {
        seek.setSpeedUpMode(false)
        this.speedUpMode = false
        return
      }

      if (enable) {
        seek.setSpeedUpMode(true)
        this.speedUpMode = true
      } else {
        seek.setSpeedUpMode(false)
        this.speedUpMode = false
      }
    }
  }

  Video.registerComponent('MobileControlLayer', MobileControlLayer)
}
