import HeaderBox from 'component/PageModalHeaderBox'

import { StyledSwiper, StyledSwiperSlide } from './Styled'

import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import Tabs from 'component/Tabs'
import { HeaderWrap, PageWrapperNonScroll } from 'commonStyledComponents'
import { TabsType } from 'component/Tabs/Styled'

export default forwardRef(function PageTabs(
  {
    uuid,
    children,
    title = '',
    tabs = /** @type { { id: string, name: string }[] } */ ([]),
    defaultValue,
    onClose,
    onClickTab,
    onSwipeTab,
    ...props
  },
  ref
) {
  const [tab, setTab] = useState(defaultValue ?? tabs[0]?.id)

  const tabOrDefaultTab = tab ?? tabs[0]?.id

  const map = {}

  for (const [index, tab] of tabs.entries()) {
    map[tab.id] = index
    map[index] = tab.id
  }

  // 1. if we are jumping to slide due click on tab
  //  we assume we did not swipe to it, therefore not trigger swiper event
  // 2. or if we are not jump to anywhere, just emit normally
  const jumpTo = useRef(null)

  const onProgress = (swiper) => {
    if (map[swiper.activeIndex] != null && map[swiper.activeIndex] !== tab) {
      if (jumpTo.current == null) {
        onSwipeTab?.(map[swiper.activeIndex])
      }
      if (jumpTo.current === map[swiper.activeIndex]) {
        jumpTo.current = null
      }
      setTab(map[swiper.activeIndex])
    }
  }

  const swiperRef = useRef()

  const changeTab = (tabName) => {
    if (map[tabName] === swiperRef?.current?.swiper.realIndex) {
      setTab(tabName)
    } else {
      onClickTab?.(tabName)
      jumpTo.current = tabName
      swiperRef?.current?.swiper.slideTo(map[tabName])
    }
  }

  useImperativeHandle(ref, () => ({
    changeTab,
  }))

  return (
    <PageWrapperNonScroll {...props}>
      <HeaderWrap>
        <HeaderBox style={{ flexShrink: 0 }} headerTitle={title} modalId={uuid} onClose={onClose} />
        {tabs.length > 1 && (
          <Tabs options={tabs} type={TabsType.Underline} value={tabOrDefaultTab} onChange={changeTab} />
        )}
      </HeaderWrap>
      <StyledSwiper ref={swiperRef} onSlideChange={onProgress} cssMode>
        {children}
      </StyledSwiper>
    </PageWrapperNonScroll>
  )
})

export const PageTab = StyledSwiperSlide
