import useHintModal from 'hooks/useHintModal'

import IDCard from '../component/IDCard'
import { report } from 'hooks/useAnalytics'

export default function useOpenModal() {
  const { openHintModal } = useHintModal()

  const openIDCard = () => {
    report({
      SerialNumber: 2,
      Event: 'system_settings_click',
      Trigger: '點擊帐号凭证',
      Parameters: 'content',
      Value: 'account_credentials',
    })

    openHintModal({
      children: IDCard,
      props: {},
    })
  }

  return {
    openIDCard,
  }
}
