import PageTabs, { PageTab } from 'component/PageTabs'
// import Comic from "./components/Comic";
import Short from './components/Short'
import Video from './components/Video'
import Comic from './components/Comic'

import { report, useReportAppScreen, WorkSectionProvider } from 'hooks/useAnalytics'
export default function BrowseHistory({ ...props }) {
  useReportAppScreen({
    SerialNumber: 1,
    Event: 'app_screen',
    Trigger: '觀看紀錄頁面',
    Parameters: 'page_title',
    Value: 'view_footprints_page',
  })
  return (
    <WorkSectionProvider
      SerialNumber={5}
      Event="footprints_click"
      Trigger="點擊觀看紀錄內容"
      Parameters="view_history_content_id"
    >
      <PageTabs
        {...props}
        title="浏览纪录"
        tabs={[
          { id: 'video', name: '长视频' },
          { id: 'short', name: '短视频' },
          ...(process.env.REACT_APP_HAS_PROFILE_COMIC_SUB_PAGE ? [{ id: 'comic', name: '漫画' }] : []),
        ]}
        onClose={({ close }) => {
          close()
          report({
            SerialNumber: 6,
            Event: 'footprints_click',
            Trigger: '返回',
            Parameters: 'content',
            Value: 'go_back',
          })
        }}
        onClickTab={(id) => {
          if (id === 'video') {
            report({
              SerialNumber: 2,
              Event: 'footprints_click',
              Trigger: '點擊長視頻',
              Parameters: 'content',
              Value: 'long_video',
            })
          }
          if (id === 'short') {
            report({
              SerialNumber: 3,
              Event: 'footprints_click',
              Trigger: '點擊短視頻',
              Parameters: 'content',
              Value: 'short_video',
            })
          }
          if (id === 'comic') {
            report({
              SerialNumber: 4,
              Event: 'footprints_click',
              Trigger: '點擊漫畫',
              Parameters: 'content',
              Value: 'comic',
            })
          }
        }}
      >
        <PageTab>
          <Video />
        </PageTab>
        <PageTab>
          <Short />
        </PageTab>
        {process.env.REACT_APP_HAS_PROFILE_COMIC_SUB_PAGE ? (
          <PageTab>
            <Comic />
          </PageTab>
        ) : null}
      </PageTabs>
    </WorkSectionProvider>
  )
}
