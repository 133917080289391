import { useMemo, useEffect, useState, useCallback } from 'react'
import { useAlert } from 'react-alert'
//
import { HeaderWrap, PageWrapperNonScroll } from 'commonStyledComponents'
import { fetchGameBalanceAll, fetchGamePaymentTypeList, postGamePayOrder, fetchGameDepositChannelV4 } from 'api/index'

import {
  BalanceWrapper,
  IconInputWrapper,
  Wrapper,
  RightAlignHintText,
  ThreeButtonRow,
  FourButtonRow,
  SectionTitle,
  Button,
  SectionPlaceholder,
  IconDollars,
  IconUser,
  IconInput,
  ErrorText,
} from './Styled'
import RefreshButton from 'page/GameLobby/component/RefreshButton'

import { FullWidthPrimaryButton } from 'commonStyledComponents'
import { formatAmount } from 'utils/amountFormat'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { GAME_PAYMENT_METHOD_KEYS, getPaymentMethod } from 'constant/game'
import HeaderBox from 'component/PageModalHeaderBox'
import { PageScrollWrap } from 'commonStyledComponents'
import { useNavigateTo, useParamsOfPage } from 'hooks/useNavigateTo'
import NoteBox from 'component/NoteBox'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import PageLoading from 'component/Loading/PageLoading'
import { useOpenGamePopup } from 'hooks/useOpenGamePopup'
import ChannelButton from './component/ChannelButton'
import MethodButton from './component/MethodButton'
import { useToastAlert } from 'hooks/useToastAlert'
import LoginPopup from 'component/LoginPopup'
import { useSelector } from 'react-redux'
import { selectUserInfo } from 'redux/selector/user'
import { checkDebugSecret } from 'page/SystemConfig'
import { report, useReportAppScreen } from 'hooks/useAnalytics'

/*
  Payment channels

  Method: payment type such as alipay
  Channel: payment channel in method, such as alipay_dev in alipay
*/

export default function GameDeposit() {
  const alert = useAlert()
  // const { openConfirm } = useConfirmModal()
  const { navigateToGameDepositBankTransfer, navigateToGameDepositUsdtExchange, navigateToGameDepositZqbTransfer } =
    useNavigateTo()

  // 頁面資料
  const [paymentMethodKey, setPaymentMethodKey] = useState(null) // code in lower case
  const [methodList, setMethodList] = useState([])
  const [channel, setChannel] = useState(null)
  const [channelList, setChannelList] = useState([])
  const [balance, setBalance] = useState(0)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [infoLoading, setInfoLoading] = useState(true)
  const [showRegisterPopup, setShowRegisterPopup] = useState(false)
  const userInfo = useSelector(selectUserInfo)
  const navigate = useAppNavigate()

  const { fromGame, fromGameType, fromGameTpCode, fromGameDirection } = useParamsOfPage('game-deposit')
  const openGamePopup = useOpenGamePopup()
  const { toast } = useToastAlert()

  // --動態計算資料--

  // 有渠道的支付方式
  const allAvailablePaymentMethods = useMemo(
    () => methodList.filter((m) => channelList.find((n) => n.paymentType == m.code)),
    [channelList, methodList]
  )

  const parsedDebugInfo = useMemo(() => {
    try {
      const temp = JSON.parse(localStorage.getItem('DEBUG'))
      if (Array.isArray(temp) && temp.every((i) => typeof i === 'string')) {
        if (checkDebugSecret(temp[0])) {
          return temp.slice(1)
        }
      }
    } catch (err) {}

    return []
  }, [])

  const availablePaymentMethods = useMemo(() => {
    const list = allAvailablePaymentMethods.filter((m) => {
      const method = getPaymentMethod(m.code)
      if (parsedDebugInfo.includes('PAYMENT')) {
        return true
      } else if (method) {
        return method?.hidden !== true
      } else {
        // filter out special without method record
        return m.special !== 1
      }
    })
    return list
  }, [allAvailablePaymentMethods, parsedDebugInfo])
  useEffect(() => {
    if (availablePaymentMethods.length) {
      setPaymentMethodKey(availablePaymentMethods[0]?.codeKey)
    }
    setPaymentMethodKey(null)
  }, [availablePaymentMethods, parsedDebugInfo])

  // channels in current method
  const currentMethodChannelList = useMemo(() => {
    // 忽略大小寫
    return channelList.filter((m) => m.paymentType.toLowerCase() === paymentMethodKey)
  }, [channelList, paymentMethodKey])

  const amountOptions = useMemo(() => {
    if (channel && Array.isArray(channel.specificAmounts)) {
      return [...channel.specificAmounts]
    }
    return []
  }, [channel])

  const amountLimit = useMemo(() => {
    if (channel?.amountType == 1) {
      return {
        min: channel.minAmount,
        max: channel.maxAmount,
      }
    }
    return {}
  }, [channel])

  const currentMethod = useMemo(() => getPaymentMethod(paymentMethodKey), [paymentMethodKey])

  const nameFieldVisible = useMemo(() => currentMethod?.withNameField, [currentMethod])

  const accountFieldVisible = useMemo(() => currentMethod?.withAccountField, [currentMethod])

  const bankFieldVisible = useMemo(() => currentMethod?.withBankField, [currentMethod])

  // const checkUserAccountExist = useCallback(() => {
  //   // 非遊客
  //   if (userInfo?.type !== 1) {
  //     return true
  //   } else {
  //     setShowRegisterPopup(true)
  //     return false
  //   }
  // }, [userInfo])

  const {
    values,
    errors,
    handleChange: handleChangeFormik,
    handleSubmit,
    handleBlur,
    setFieldValue,
    isValid,
    touched,
    validateField,
  } = useFormik({
    initialValues: {
      depositAmount: '',
      name: '',
      account: '',
      bank: '',
    },
    // validateOnChange: false,
    validationSchema: Yup.object().shape({
      depositAmount:
        channel?.amountType === 1
          ? Yup.number()
              .typeError('请输入数字')
              .required('请输入存款金额')
              .min(amountLimit.min, '金额应大于${min}')
              .max(amountLimit.max, '金额应小于${max}')
          : Yup.number(),
      name: currentMethod?.nameRequired ? Yup.string().required('请输入存款人姓名') : Yup.string(),
      account: currentMethod?.accountRequired
        ? Yup.string()
            .required('请输入银行帐号')
            .matches(/^[0-9]+$/, '请输入数字')
        : Yup.string(),
      bank: bankFieldVisible ? Yup.string().required('请输入银行名称') : Yup.string(),
    }),
    onSubmit: async () => {
      await submitDeposit()
    },
  })

  const onClickSubmit = useCallback(
    (ev) => {
      report({
        SerialNumber: 5,
        Event: 'deposit_click',
        Trigger: '提交申請',
        Parameters: 'content',
        Value: 'submit_application',
      })
      return handleSubmit(ev)
    },
    [handleSubmit]
  )

  const handleChange = useCallback(
    (ev) => {
      const value = ev.currentTarget.value
      if (/^\d*$/.test(value)) {
        report({
          SerialNumber: 4,
          Event: 'deposit_click',
          Trigger: '點擊輸入存款金額',
          Parameters: 'deposit_amount',
          Value: value,
        })
      }
      handleChangeFormik(ev)
    },
    [handleChangeFormik]
  )

  const handleMethodSelect = useCallback(async (data) => {
    setPaymentMethodKey(data.codeKey)
  }, [])

  const handleMethodClick = useCallback(
    (data) => {
      report({
        SerialNumber: 2,
        Event: 'deposit_click',
        Trigger: '選擇支付方式',
        Parameters: 'payment_method_id',
        Value: data.id,
      })
      return handleMethodSelect(data)
    },
    [handleMethodSelect]
  )

  // 動態元件
  const createMethodButton = useCallback(
    (data) => {
      return (
        <MethodButton
          key={data.id}
          onClick={() => handleMethodClick(data)}
          data={data}
          active={data.codeKey === paymentMethodKey}
        ></MethodButton>
      )
    },
    [handleMethodClick, paymentMethodKey]
  )

  const handleChannelSelect = useCallback((channel) => {
    setChannel(channel)
  }, [])
  const handleChannelButtonClick = useCallback(
    (channel) => {
      report({
        SerialNumber: 3,
        Event: 'deposit_click',
        Trigger: '選擇支付通道',
        Parameters: 'payment_channel_id',
        Value: channel.id,
      })
      return handleChannelSelect(channel)
    },
    [handleChannelSelect]
  )

  const methodButtons = useMemo(
    () =>
      availablePaymentMethods.length ? (
        <FourButtonRow>{availablePaymentMethods.map((m) => createMethodButton(m))}</FourButtonRow>
      ) : (
        <SectionPlaceholder>暂无可用支付方式</SectionPlaceholder>
      ),
    [availablePaymentMethods, createMethodButton]
  )

  const channelButtons = useMemo(
    () =>
      currentMethodChannelList.length ? (
        <ThreeButtonRow>
          {currentMethodChannelList.map((m) => (
            <ChannelButton key={m.id} onClick={() => handleChannelButtonClick(m)} data={m} active={m.id == channel?.id}>
              {m.name}
            </ChannelButton>
          ))}
        </ThreeButtonRow>
      ) : (
        <SectionPlaceholder>暂无可用支付渠道</SectionPlaceholder>
      ),
    [currentMethodChannelList, channel?.id, handleChannelButtonClick]
  )

  const amountButtons = amountOptions.map((m) => (
    <Button
      key={m}
      onClick={() => {
        setFieldValue('depositAmount', m)
        report({
          SerialNumber: 6,
          Event: 'deposit_click',
          Trigger: '點擊存款金額',
          Parameters: 'deposit_amount',
          Value: m,
        })
      }}
      className={m == values.depositAmount ? 'active' : ''}
    >
      {formatAmount(m)}元
    </Button>
  ))

  async function submitDeposit() {
    if (submitLoading) {
      alert.show('请勿重复提交', { position: 'middle' })
      return
    }
    if (paymentMethodKey === GAME_PAYMENT_METHOD_KEYS.SELF_DEBIT) {
      // bank transfer
      return toBankTransfer(channel.id, values.depositAmount)
    } else if (paymentMethodKey === GAME_PAYMENT_METHOD_KEYS.SELF_USDT) {
      // usdt
      return toUsdtExchange(channel.id, values.depositAmount)
    }
    const payload = {
      amount: values.depositAmount.toString(),
      name: '',
      account: '',
      bank: '',
      paymentChannelId: channel.id,
    }
    if (nameFieldVisible) {
      payload.name = values.name.toString()
    }
    if (accountFieldVisible) {
      payload.account = values.account.toString()
    }
    if (bankFieldVisible) {
      payload.bank = values.bank.toString()
      payload.name = values.name.toString()
      payload.account = values.account.toString()
    }
    const newWindow = window.open('', '_blank')
    setTimeout(() => {
      // prevent ios touch area offset
      window.scrollTo(0, 0)
    }, 100)
    try {
      setSubmitLoading(true)
      const data = await postGamePayOrder(payload)
      if (!data.paymentLink) {
        alert.show('无可用付款连结', { position: 'middle' })
      }
      newWindow.location.href = data.paymentLink
      navigate(-1)
    } catch (ex) {
      console.warn(ex)
      newWindow.close()
      if (ex && ex.message) alert.show('存款失败', { position: 'middle' })
    } finally {
      setSubmitLoading(false)
    }
  }

  const loadInfo = useCallback(async () => {
    try {
      setInfoLoading(true)
      await handleRefreshBalance()
      await handleLoadPaymentChannels()
    } catch (ex) {
      ex?.message && toast(ex?.message)
    }
    setInfoLoading(false)
  }, [toast])

  async function handleRefresh() {
    report({
      SerialNumber: 8,
      Event: 'deposit_click',
      Trigger: '更新餘額',
      Parameters: 'content',
      Value: 'update_balance',
    })

    try {
      await handleRefreshBalance()
      await handleLoadPaymentChannels()
    } catch (ex) {
      ex?.message && toast(ex?.message)
    }
  }

  function onClickRefresh() {
    return handleRefresh()
  }

  async function handleRefreshBalance() {
    const data = await fetchGameBalanceAll()
    setBalance(data.total)
  }

  async function handleLoadPaymentChannels() {
    try {
      const paymentTypes = await fetchGamePaymentTypeList()
      const channelList = await fetchGameDepositChannelV4({})
      const data = paymentTypes.data.map((m) => {
        // ATTENTION: 正規化PaymentType code，全轉小寫用於比對
        m.codeKey = m.code?.toLowerCase()
        return m
      })

      setMethodList(data)
      setChannelList(channelList.data)
    } catch {
      toast('资料读取异常')
    }
  }

  function toUsdtExchange(channel, amount) {
    navigateToGameDepositUsdtExchange({
      payment_channel_id: channel,
      amount,
    })
  }

  function toBankTransfer(channel, amount) {
    navigateToGameDepositBankTransfer({
      payment_channel_id: channel,
      amount,
    })
  }

  function toZqbTransfer(channel, amount) {
    navigateToGameDepositZqbTransfer({
      payment_channel_id: channel,
      amount,
    })
  }

  const handleFinishRegister = useCallback(async () => {
    setShowRegisterPopup(false)
  }, [])

  useEffect(() => {
    // checkUserAccountExist()
    loadInfo()
  }, [loadInfo, userInfo])

  // auto set first method
  useEffect(() => {
    if (availablePaymentMethods.length) handleMethodSelect(availablePaymentMethods[0])
  }, [handleMethodSelect, availablePaymentMethods])

  // auto set first channel
  useEffect(() => {
    if (currentMethodChannelList.length) handleChannelSelect(currentMethodChannelList[0])
  }, [currentMethodChannelList, handleChannelSelect])

  useEffect(() => {
    // 如果是固定金額，自動選擇第一個選項
    if (channel && channel?.amountType !== 1 && amountOptions?.length) {
      setFieldValue('depositAmount', amountOptions[0])
      validateField('depositAmount')
    } else if (channel?.amountType === 1) {
      setFieldValue('depositAmount', '')
      validateField('depositAmount')
    }
  }, [channel, amountOptions, setFieldValue, validateField])

  // 人名區塊
  const nameSection = nameFieldVisible ? (
    <>
      <SectionTitle>存款人姓名</SectionTitle>
      <IconInputWrapper>
        <IconUser></IconUser>
        <IconInput
          name="name"
          type="string"
          value={values.name}
          placeholder="请输入存款人姓名"
          onBlur={handleBlur}
          onChange={handleChange}
        ></IconInput>
      </IconInputWrapper>
      <ErrorText>{touched.name && errors.name}</ErrorText>
    </>
  ) : (
    <></>
  )

  // 人名區塊
  const accountField = accountFieldVisible ? (
    <>
      <SectionTitle>存款帐号</SectionTitle>
      <IconInputWrapper>
        <IconUser></IconUser>
        <IconInput
          name="account"
          type="string"
          value={values.account}
          placeholder="请输入存款帐号"
          onBlur={handleBlur}
          onChange={handleChange}
        ></IconInput>
      </IconInputWrapper>
      <ErrorText>{touched.account && errors.account}</ErrorText>
    </>
  ) : (
    <></>
  )

  const bankSection = bankFieldVisible ? (
    <>
      <SectionTitle>银行名称</SectionTitle>
      <IconInputWrapper>
        <IconUser></IconUser>
        <IconInput
          name="bank"
          type="string"
          value={values.bank}
          placeholder="请输入银行名称"
          onBlur={handleBlur}
          onChange={handleChange}
        ></IconInput>
      </IconInputWrapper>
      <ErrorText>{touched.bank && errors.bank}</ErrorText>
      <SectionTitle>银行帐号</SectionTitle>
      <IconInputWrapper>
        <IconUser></IconUser>
        <IconInput
          name="account"
          type="string"
          value={values.account}
          placeholder="请输入银行帐号"
          onBlur={handleBlur}
          onChange={handleChange}
        ></IconInput>
      </IconInputWrapper>
      <ErrorText>{touched.account && errors.account}</ErrorText>
      <SectionTitle>转帐人姓名</SectionTitle>
      <IconInputWrapper>
        <IconUser></IconUser>
        <IconInput
          name="name"
          type="string"
          value={values.name}
          placeholder="请输入转帐人姓名"
          onBlur={handleBlur}
          onChange={handleChange}
        ></IconInput>
      </IconInputWrapper>
      <ErrorText>{touched.name && errors.name}</ErrorText>
    </>
  ) : (
    <></>
  )

  // 金額區塊
  // amountType: 1.任意模式; 2.固定模式
  const amountSubmitSection = channel ? (
    <>
      {nameSection}
      {accountField}
      {bankSection}
      <SectionTitle>存款金额</SectionTitle>
      {channel?.amountType == 1 && (
        <>
          <IconInputWrapper>
            <IconDollars></IconDollars>
            <IconInput
              name="depositAmount"
              type="text"
              inputMode="numeric"
              value={values.depositAmount}
              placeholder="请输入存款金额"
              onBlur={handleBlur}
              onChange={handleChange}
            ></IconInput>
          </IconInputWrapper>
          <RightAlignHintText>
            请输入{formatAmount(amountLimit?.min)}~{formatAmount(amountLimit?.max)}的整数
          </RightAlignHintText>
          <ErrorText>{touched.depositAmount && errors.depositAmount}</ErrorText>
        </>
      )}

      <FourButtonRow>{amountButtons}</FourButtonRow>
      <FullWidthPrimaryButton type="submit" onClick={onClickSubmit} disabled={!isValid}>
        提交申请
      </FullWidthPrimaryButton>
    </>
  ) : (
    <></>
  )

  let paymentPageContent = null
  paymentPageContent = (
    <Wrapper>
      <BalanceWrapper>
        <div className="card-title">余额</div>
        <div className="card-holder">
          {formatAmount(balance)}
          <RefreshButton
            img={`${process.env.PUBLIC_URL}/icon/refresh.svg`}
            onClick={onClickRefresh}
            buttonBackground={false}
          ></RefreshButton>
        </div>
      </BalanceWrapper>
      <SectionTitle>选择支付方式</SectionTitle>
      {methodButtons}
      <SectionTitle>选择支付通道</SectionTitle>
      {channelButtons}
      {amountSubmitSection}
      <NoteBox
        background="none"
        text={['请务必提供真实资讯，以供核对转帐资料。', '请在汇款备注写入真实姓名及相关资讯，以加速充值流程。']}
      ></NoteBox>
      {infoLoading && <PageLoading />}
    </Wrapper>
  )

  const handleClose = useCallback(
    ({ close }) => {
      if (fromGame != null) {
        openGamePopup({
          id: fromGame,
          tpCode: fromGameTpCode,
          gameType: fromGameType,
          direction: fromGameDirection,
        })
      }
      close()

      report({
        SerialNumber: 7,
        Event: 'deposit_click',
        Trigger: '返回',
        Parameters: 'content',
        Value: 'go_back',
      })
    },
    [fromGame, fromGameDirection, fromGameTpCode, fromGameType, openGamePopup]
  )

  const handleCloseRegister = useCallback(() => {
    setShowRegisterPopup(false)
    // if (fromGame != null) {
    //   openGamePopup({
    //     id: fromGame,
    //     tpCode: fromGameTpCode,
    //     gameType: fromGameType,
    //     direction: fromGameDirection,
    //   })
    // }
    // navigate(-1)
  }, [])

  useReportAppScreen({
    SerialNumber: 1,
    Event: 'app_screen',
    Trigger: '存款頁面',
    Parameters: 'page_title',
    Value: 'deposit_page',
  })

  return (
    <PageWrapperNonScroll>
      <HeaderWrap>
        <HeaderBox headerTitle={'存款'} onClose={handleClose} />
      </HeaderWrap>

      <PageScrollWrap>{paymentPageContent}</PageScrollWrap>
      {showRegisterPopup && <LoginPopup onClose={handleCloseRegister} onFinish={handleFinishRegister}></LoginPopup>}
    </PageWrapperNonScroll>
  )
}
