import { useCallback } from 'react'
import { TopicTextWrapper, Topics, UploaderInfoElement, UploaderName, VideoTitle } from './Styled'
import { useNavigateTo } from 'hooks/useNavigateTo'
import { report } from 'hooks/useAnalytics'

const UploaderInfo = ({ uploaderId = '', uploader = '', videoName = '', topics = [], ...props }) => {
  const { navigateToViewUploader } = useNavigateTo()
  const openUploaderPage = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()
      navigateToViewUploader({
        uploaderId,
      })
      report({
        SerialNumber: 5,
        Event: 'short_video_playback_click',
        Trigger: '點擊播放影片',
        Parameters: 'uploader_name_id',
        Value: uploaderId,
      })
    },
    [navigateToViewUploader, uploaderId]
  )

  const { navigateToViewMore } = useNavigateTo()

  const onClick = (text, params) => {
    navigateToViewMore({
      showAd: false,
      headerTitle: text,
      type: 'short',
      params,
    })
  }

  return (
    <UploaderInfoElement {...props}>
      {topics.length > 0 && (
        <Topics>
          <TopicTextWrapper>
            {topics.map((t, index) => (
              <span
                key={index}
                onClick={() => {
                  onClick(t.text, t.params)
                  report({
                    SerialNumber: 4,
                    Event: 'short_video_playback_click',
                    Trigger: '選擇 hashtag',
                    Parameters: 'hashtag_id',
                    Value: t.id,
                  })
                }}
              >
                {t.text}
              </span>
            ))}
          </TopicTextWrapper>
        </Topics>
      )}
      <UploaderName onClick={openUploaderPage}>{uploader}</UploaderName>
      <VideoTitle>{videoName}</VideoTitle>
    </UploaderInfoElement>
  )
}

export default UploaderInfo
