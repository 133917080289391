import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import BeatLoader from 'react-spinners/BeatLoader'

import { FETCH_USER_INFO } from 'redux/constant/user'

import HeaderBox from 'component/PageModalHeaderBox'
import { UserNameFieldBox, PasswordFieldBox } from 'component/TextFieldBox'

import { fetchLogin } from 'api'

import { validationSchema } from './validation'
import { ContentBox, BtnSubmit, BtnSwitchLogin, BottomText, StyledPageScrollWrap } from './Styled'
import { HeaderWrap, PageWrapperNonScroll } from 'commonStyledComponents'
import { useNavigateTo } from 'hooks/useNavigateTo'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import { useToastAlert } from 'hooks/useToastAlert'
import { report, useReportAppScreen } from 'hooks/useAnalytics'

export default function Login() {
  const dispatch = useDispatch()
  const { navigateToRegister } = useNavigateTo()
  const navigate = useAppNavigate()
  const [isLoading, setIsLogin] = useState(false)

  const { toast } = useToastAlert()

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      user_name: '',
      password: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape(validationSchema),
    onSubmit: () => {
      handleLogin()
    },
  })

  const onClickSubmit = useCallback(
    (ev) => {
      report({
        SerialNumber: 5,
        Event: 'login_click',
        Trigger: '點擊立即登入',
        Parameters: 'content',
        Value: 'login_now',
      })
      return handleSubmit(ev)
    },
    [handleSubmit]
  )

  const openRegisterModal = useCallback(() => {
    report({
      SerialNumber: 5,
      Event: 'login_click',
      Trigger: '點擊去註冊',
      Parameters: 'content',
      Value: 'go_register',
    })
    navigateToRegister({}, { replace: true })
  }, [navigateToRegister])

  const handleLogin = () => {
    setIsLogin(true)

    const data = {
      user_name: values.user_name,
      password: values.password,
    }

    fetchLogin(data)
      .then((response) => {
        // 顯示成功訊息
        toast('登入成功')
        // openHintModal({
        //   children: LoginSuccess,
        //   props: {},
        // })

        // 重新使用deviceId獲取用戶資料
        dispatch({ type: FETCH_USER_INFO })

        setIsLogin(false)

        // 關閉Modal
        navigate(-1)
        navigate('/', { replace: true, forceReplaceNonCurrentPage: true })
      })
      .catch((error) => {
        toast('登入失败')
        setIsLogin(false)
      })
  }

  useReportAppScreen({
    SerialNumber: 1,
    Event: 'app_screen',
    Trigger: '登入會員頁面',
    Parameters: 'page_title',
    Value: 'login_page',
  })

  return (
    <PageWrapperNonScroll>
      <HeaderWrap>
        <HeaderBox
          headerTitle={'登入会员'}
          onClose={({ close }) => {
            close()
            report({
              SerialNumber: 6,
              Event: 'login_click',
              Trigger: '點擊返回',
              Parameters: 'content',
              Value: 'go_back',
            })
          }}
        />
      </HeaderWrap>
      <StyledPageScrollWrap noPadding>
        <ContentBox>
          <UserNameFieldBox
            name="user_name"
            placeholder="输入帐号"
            error={errors.user_name}
            handleChange={handleChange}
            onClick={() => {
              report({
                SerialNumber: 2,
                Event: 'login_click',
                Trigger: '點擊請輸入帳號',
                Parameters: 'content',
                Value: 'please_enter_account',
              })
            }}
          />

          <PasswordFieldBox
            name="password"
            placeholder={'输入密码'}
            error={errors.password}
            handleChange={handleChange}
            onClick={() => {
              report({
                SerialNumber: 3,
                Event: 'login_click',
                Trigger: '點擊請輸入密碼',
                Parameters: 'content',
                Value: 'please_enter_password',
              })
            }}
          />

          <BtnSubmit type="submit" disabled={isLoading} onClick={onClickSubmit}>
            {isLoading ? <BeatLoader size={12} margin={2} color={'#da9dc8'} /> : '登入'}
          </BtnSubmit>

          <BottomText>
            <span>没有帐号</span>
            <BtnSwitchLogin onClick={openRegisterModal}>去注册</BtnSwitchLogin>
          </BottomText>
        </ContentBox>
      </StyledPageScrollWrap>
    </PageWrapperNonScroll>
  )
}
