import {
  fetchAddComicCollectList,
  fetchComicChapterKey,
  fetchComicChapters,
  fetchComicList,
  fetchComicViewChapter,
  fetchRemoveComicCollectList,
} from 'api'
import AesImage from 'component/AesImage'
import { DISABLED, ENABLED } from 'constant/common'
import { useAllComicChapters } from 'hooks/useAllComicChapters'
import { Chapters } from 'page/ViewComic/components/Chapters'
import { useEffect, useRef, useState } from 'react'
import {
  Header,
  Footer,
  Wrapper,
  FooterButton,
  FooterButtonIconChapter,
  StyledAnimatedIcon,
  FooterButtonIconChapterPrev,
  FooterButtonIconChapterNext,
  HeaderClose,
  BottomHint,
} from './Styled'
import saveOutline from 'page/ViewComic/animated-icons/save_outline.json'
import Tutorial from './components/Tutorial'
import { useDispatch } from 'react-redux'
import { RESET_COMIC_COLLECTION } from 'redux/constant/comicCollection'
import useAlertWorksActionResult from 'hooks/useAlertWorksActionResult'
import { useNavigateTo, useParamsOfPage } from 'hooks/useNavigateTo'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import { combinedStorage } from 'utils/combinedStorage'
import { report } from 'hooks/useAnalytics'

const TUTORIAL_SKIP_VARIABLE = 'viewedComicTutorialAt'

export default function ViewComicChapter() {
  const dispatch = useDispatch()

  const { chapterInfo: initialChapterInfo, comicId } = useParamsOfPage('comic-chapter')
  const navigate = useAppNavigate()
  const { navigateToViewComicChapter } = useNavigateTo()

  const [currentChapterInfo, setCurrentChapterInfo] = useState(null)
  const chapterInfo = currentChapterInfo
    ? currentChapterInfo.id === initialChapterInfo.id
      ? currentChapterInfo
      : initialChapterInfo
    : initialChapterInfo

  const [comicInfo, setComicInfo] = useState(null)
  const [chapterListVisible, setChapterListVisible] = useState(false)

  const fullChapterList = useAllComicChapters(comicId)
  let chapterIndex = -1

  for (let i = 0; i < fullChapterList.length; i++) {
    if (fullChapterList[i].id === chapterInfo.id) {
      chapterIndex = i
      break
    }
  }

  const prevChapter = fullChapterList[chapterIndex - 1]
  const nextChapter = fullChapterList[chapterIndex + 1]

  const { favorite, last_read_chapter_id, latest_chapter_prefix_name } = comicInfo ?? {}

  useEffect(() => {
    const con = new AbortController()
    const sig = con.signal
    fetchComicList({
      signal: sig,
      id: comicId,
    }).then((res) => {
      if (res.data[0]) {
        setComicInfo({
          ...res.data[0],
          // force set current chapter even it is not loaded yet
          last_read_chapter_id: initialChapterInfo.id,
        })
      }
    })

    return () => {
      con.abort()
    }
  }, [comicId, initialChapterInfo.id])

  useEffect(() => {
    if (chapterInfo.page_url == null) {
      const con = new AbortController()
      const sig = con.signal
      fetchComicChapters({ signal: sig, id: chapterInfo.id }).then((res) => {
        if (!sig.aborted) {
          setCurrentChapterInfo(res.data[0])
        }
      })

      return () => {
        con.abort()
      }
    }
  }, [chapterInfo.id, chapterInfo.page_url])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    fetchComicViewChapter({ signal, chapter_id: chapterInfo.id })
  }, [chapterInfo.id])

  const [keyState, setKeyState] = useState({
    chapter: null,
    key: null,
  })

  useEffect(() => {
    if (keyState.chapter === chapterInfo.id) return
    const controller = new AbortController()
    const signal = controller.signal

    fetchComicChapterKey({ signal, id: chapterInfo.id })
      .then((data) => {
        setKeyState({
          chapter: chapterInfo.id,
          key: data.key,
        })
      })
      .catch((err) => {
        if (!signal.aborted) {
          throw err
        }
      })

    return () => {
      controller.abort()
    }
  }, [chapterInfo.id, keyState.chapter])

  const onOpenChapter = (chapter) => {
    setChapterListVisible(false)
    // setCurrentChapterInfo(chapter)
    navigateToViewComicChapter(
      {
        chapterInfo: chapter,
        comicId,
      },
      { replace: true }
    )

    setComicInfo((v) => {
      if (v != null) {
        return {
          ...v,
          last_read_chapter_id: chapter.id,
        }
      } else {
        return v
      }
    })

    if (scrollArea.current) {
      scrollArea.current.scrollTop = 0
    }
  }

  const scrollArea = useRef(/** @type {undefined|HTMLDivElement} */ (undefined))
  const onAreaScroll = () => {
    setUiHIdden(true)
  }

  /**
   *
   * @param {React.SyntheticEvent<'img', MouseEvent>} ev
   */
  const onAreaClick = (ev) => {
    if (uiHidden) {
      report({
        SerialNumber: 2,
        Event: 'comic_reading_click',
        Trigger: '點擊畫面用來顯示閱讀控制',
        Parameters: 'content',
        Value: 'show_reading_controls',
      })
    } else {
      report({
        SerialNumber: 3,
        Event: 'comic_reading_click',
        Trigger: '點擊畫面用來顯示閱讀控制',
        Parameters: 'content',
        Value: 'close_reading_controls',
      })
    }
    setUiHIdden((v) => !v)
  }
  const [uiHidden, setUiHIdden] = useState(false)

  const alerts = useAlertWorksActionResult()

  const setFavorite = async (value) => {
    if (value) {
      await fetchAddComicCollectList({ comic_id: comicId })
    } else {
      await fetchRemoveComicCollectList({ comic_id: comicId })
    }

    dispatch({ type: RESET_COMIC_COLLECTION })

    setComicInfo((v) => {
      if (v == null) return null
      return {
        ...v,
        favorite: v.favorite === ENABLED ? DISABLED : ENABLED,
      }
    })

    if (value) {
      alerts.alertSaved()
    } else {
      // alerts.alertUnsaved()
    }
  }

  const bottomAnchor = useRef()

  const [skipTutorial] = useState(() => {
    return combinedStorage.getItem(TUTORIAL_SKIP_VARIABLE) === process.env.REACT_APP_VERSION
  })

  const setTutorialSkip = () => {
    combinedStorage.setItem(TUTORIAL_SKIP_VARIABLE, process.env.REACT_APP_VERSION)
  }

  return (
    <>
      <Wrapper ref={scrollArea} onScroll={onAreaScroll} onClick={onAreaClick}>
        {keyState.chapter === chapterInfo.id &&
          chapterInfo?.page_url.map((url, index) => (
            <AesImage key={index} src={url} decryptKey={keyState.key} initialAspectRatio={390 / 844} />
          ))}
        <BottomHint ref={bottomAnchor}>已经到底了</BottomHint>
      </Wrapper>
      <Header className={uiHidden ? 'hidden' : ''}>
        <HeaderClose
          onClick={() => {
            navigate(-1)
            report({
              SerialNumber: 3,
              Event: 'comic_reading_click',
              Trigger: '返回',
              Parameters: 'content',
              Value: 'go_back',
            })
          }}
        ></HeaderClose>
        {chapterInfo.prefix_name + (chapterInfo.title ? ` - ${chapterInfo.title}` : '')}
      </Header>
      <Footer className={uiHidden ? 'hidden' : ''}>
        <FooterButton
          disabled={prevChapter == null}
          onClick={() => {
            onOpenChapter(prevChapter)
            report({
              SerialNumber: 3,
              Event: 'comic_reading_click',
              Trigger: '點擊上一話',
              Parameters: 'content',
              Value: 'previous_chapter',
            })
          }}
        >
          <FooterButtonIconChapterPrev />
          上一章
        </FooterButton>
        <FooterButton
          onClick={() => {
            setChapterListVisible((v) => !v)
            report({
              SerialNumber: 4,
              Event: 'comic_reading_click',
              Trigger: '點擊目錄',
              Parameters: 'content',
              Value: 'directory',
            })
          }}
        >
          <FooterButtonIconChapter />
          目录
        </FooterButton>
        <FooterButton
          onClick={() => {
            setFavorite(favorite === DISABLED)
            if (favorite === DISABLED) {
              report({
                SerialNumber: 9,
                Event: 'comic_reading_click',
                Trigger: '點擊收藏',
                Parameters: 'content',
                Value: 'remove_bookmark',
              })
            } else {
              report({
                SerialNumber: 6,
                Event: 'comic_reading_click',
                Trigger: '點擊取消收藏',
                Parameters: 'content',
                Value: 'directory',
              })
            }
          }}
        >
          {comicInfo != null && <StyledAnimatedIcon animationData={saveOutline} active={favorite === ENABLED} />}
          {favorite === ENABLED ? '已收藏' : '收藏'}
        </FooterButton>
        <FooterButton
          disabled={nextChapter == null}
          onClick={() => {
            onOpenChapter(nextChapter)
            report({
              SerialNumber: 7,
              Event: 'comic_reading_click',
              Trigger: '點擊下一話',
              Parameters: 'content',
              Value: 'next_chapter',
            })
          }}
        >
          <FooterButtonIconChapterNext />
          下一章
        </FooterButton>
      </Footer>
      <Tutorial isOpen={!skipTutorial} onFinished={setTutorialSkip} />
      <Chapters
        section="comic_reading"
        title={`更新到${latest_chapter_prefix_name}`}
        comicId={comicId}
        isOpen={chapterListVisible}
        onClose={() => setChapterListVisible(false)}
        onOpenChapter={onOpenChapter}
        activeChapter={last_read_chapter_id}
        preloadedList={fullChapterList}
      />
    </>
  )
}
