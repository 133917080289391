import { useState } from 'react'

import { Wrapper, VideoName, ImageWrapper } from './Styled'
import Base64Image from 'component/Base64Image'
import { useHasPayment } from 'hooks/useHasPayment'
import { usePaymentTypeImage } from 'component/PaymentTypeImages/hooks/usePaymentTypeImage'
import { useNavigateTo } from 'hooks/useNavigateTo'
import { report, useCurrentWorkSection } from 'hooks/useAnalytics'

/**
 * @typedef {import("../../../types/api").SchemaVideoShow} SchemaVideoShow
 */

/**
 * @typedef {{
 *   videoInfo: SchemaVideoShow,
 *   width: number,
 *   columns: number,
 * }} Props
 */

/** 元件 - 影片 Item */
export default function VideoBox(
  /** @type {Props} */ {
    videoInfo,
    width,
    textAlign = /** @type {'left'|'center'|'right'} */ ('left'),
    columns = 2,
    allowUnload = true,
    noLazy,
    ...props
  }
) {
  const hasPayment = useHasPayment()
  const { navigateToViewVideo } = useNavigateTo()
  const {
    title,
    coverphoto_h,
    // view_count = 0,
  } = videoInfo

  const imageJSX = usePaymentTypeImage(videoInfo)

  const [img, setImg] = useState()

  // const formattedViewCount = view_count > 1000 ? `${(view_count / 1000).toFixed(1).replace('.0', '')}K` : String(view_count)

  const workSection = useCurrentWorkSection()

  const onVideoBoxClick = () => {
    if (workSection) {
      report({
        Parameters: 'video_id',
        Value: videoInfo.id,
        ...workSection,
      })
    }
    navigateToViewVideo({
      ...videoInfo,
      posterImg: img,
    })
  }

  return (
    <Wrapper {...props} onClick={onVideoBoxClick} width={width} columns={columns}>
      <ImageWrapper>
        <Base64Image
          aspectRatio={16 / 9}
          src={coverphoto_h}
          onImageLoad={setImg}
          noLazy={noLazy}
          allowUnload={allowUnload}
        />
        {/* <VideoInfo>
          <VideoViewCount>
            <Viewed />
            {formattedViewCount}
          </VideoViewCount>
        </VideoInfo> */}
        {hasPayment && imageJSX}
      </ImageWrapper>
      <VideoName textAlign={textAlign}>{title}</VideoName>
    </Wrapper>
  )
}
