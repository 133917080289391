import HeaderBox from 'component/PageModalHeaderBox'

import { BlockColor, Narrative, BlockContent, QqIcon, BtnContact, StyledPageScrollWrap } from './Styled'
import { HeaderWrap, PageWrapperNonScroll } from 'commonStyledComponents'
import { useEffect, useState } from 'react'
import { fetchCooperation } from 'api'
import EmptyResult from 'component/EmptyResult'
import { catchPromiseCancel } from 'utils/catchPromiseCancel'
import { report, useReportAppScreen } from 'hooks/useAnalytics'

/**
 * @param {import('../../../types/api').SchemaCooperation} coop cooperation
 */
const onBtnContactClick = (coop) => {
  report({
    SerialNumber: 2,
    Event: 'business_cooperation_click',
    Trigger: '點擊立即加入',
    Parameters: 'cooperation_now_id',
    Value: coop.id,
  })
  window.open(coop.link, '_blank')
}

export default function BusinessRelationship() {
  const [coops, setCoops] = useState(/** @type {import('../../../types/api').SchemaCooperation[]} */ ([]))
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    fetchCooperation({ signal })
      .then((res) => {
        setCoops(res.data)
        setLoaded(true)
      })
      .catch(catchPromiseCancel)

    return () => {
      controller.abort()
    }
  }, [])

  useReportAppScreen({
    SerialNumber: 1,
    Event: 'app_screen',
    Trigger: '商務合作頁面',
    Parameters: 'page_title',
    Value: 'business_cooperation_page',
  })

  return (
    <PageWrapperNonScroll>
      <HeaderWrap>
        <HeaderBox
          headerTitle={'商务合作'}
          onClose={({ close }) => {
            close()
            report({
              SerialNumber: 5,
              Event: 'business_cooperation_click',
              Trigger: '返回',
              Parameters: 'content',
              Value: 'go_back',
            })
          }}
        />
      </HeaderWrap>
      <StyledPageScrollWrap>
        {coops.map((coop, index) => (
          <BlockColor key={index}>
            {coop.link_type === 1 ? (
              <QqIcon src={process.env.PUBLIC_URL + '/icon/tg-icon.png'} />
            ) : (
              <QqIcon src={process.env.PUBLIC_URL + '/icon/qq-icon.png'} />
            )}
            <BlockContent>
              <Narrative>
                <p>{coop.name}</p>
                <p>{coop.desc}</p>
              </Narrative>
              <BtnContact onClick={() => onBtnContactClick(coop)}>立即加入</BtnContact>
            </BlockContent>
          </BlockColor>
        ))}

        {loaded && coops.length === 0 && <EmptyResult style={{ height: 'calc(100% - 2rem)' }} />}
      </StyledPageScrollWrap>
    </PageWrapperNonScroll>
  )
}
