import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useImmer } from 'use-immer'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import BeatLoader from 'react-spinners/BeatLoader'
//
import { fetchRegister, fetchUserInfo } from 'api'
//
import { FETCH_USER_INFO } from 'redux/constant/user'
//
import HeaderBox from 'component/PageModalHeaderBox'
import { UserNameFieldBox, PasswordFieldBox } from 'component/TextFieldBox'
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH, USERNAME_MIN_LENGTH } from 'constant/common'

import { ContentBox, BtnSubmit, BtnSwitchLogin, BottomText, StyledPageScrollWrap } from 'page/Login/Styled'
import { HeaderWrap, PageWrapperNonScroll } from 'commonStyledComponents'
import { useNavigateTo } from 'hooks/useNavigateTo'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import { useToastAlert } from 'hooks/useToastAlert'
import { report, useReportAppScreen } from 'hooks/useAnalytics'

export const validationSchema = {
  user_name: Yup.string().required('请输入帐号').min(USERNAME_MIN_LENGTH, `帐号需要大于${USERNAME_MIN_LENGTH}个字元`),
  password: Yup.string().required('请输入密码').min(PASSWORD_MIN_LENGTH, `密码需要大于${PASSWORD_MIN_LENGTH}个字元`),
  confirm_password: Yup.string()
    .required('请再次确认密码')
    .oneOf([Yup.ref('password'), null], '密码与确认密码需一致'),
}

export default function Register() {
  const dispatch = useDispatch()
  const { navigateToLogin } = useNavigateTo()
  const navigate = useAppNavigate()
  const { toast } = useToastAlert()

  const [state, updateState] = useImmer({
    isLoading: false,
    user_name: '',
    password: '',

    // Self Used
    applyVerificationCodeTimer: null,
    confirmPwd: '',
  })

  const handleRegister = useCallback(
    (params) => {
      updateState((draft) => void (draft.isLoading = true))

      fetchRegister(params)
        .then((response) => {
          fetchUserInfo()
            .then(() => {
              // 重新使用deviceId獲取用戶資料
              dispatch({ type: FETCH_USER_INFO })

              // 顯示成功訊息
              toast('注册成功')

              navigate(-1)
              navigate('/', { replace: true, forceReplaceNonCurrentPage: true })
            })
            .catch((error) => {
              toast('获取帐户资料失败', error?.message)
              // openHintModal({
              //   children: PopupFailed,
              //   props: {
              //     message: ['獲取帳戶資料失敗', error?.message],
              //   },
              // })
            })
            .finally(() => {
              updateState((draft) => void (draft.isLoading = false))
            })
        })
        .catch((error) => {
          toast('注册失败', error?.message)
          // openHintModal({
          //   children: PopupFailed,
          //   props: {
          //     message: ['註冊失敗', error?.message],
          //   },
          // })
          updateState((draft) => void (draft.isLoading = false))
        })
    },
    [updateState, dispatch, toast, navigate]
  )

  const openLoginModal = useCallback(() => {
    report({
      SerialNumber: 6,
      Event: 'register_click',
      Trigger: '點擊去登入',
      Parameters: 'content',
      Value: 'go_login',
    })
    // 關閉Modal
    navigateToLogin({}, { replace: true })
  }, [navigateToLogin])

  const { errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      user_name: '',
      password: '',
      confirm_password: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape(validationSchema),
    onSubmit: handleRegister,
  })

  const onClickSubmit = useCallback(
    (ev) => {
      report({
        SerialNumber: 5,
        Event: 'register_click',
        Trigger: '點擊立即註冊',
        Parameters: 'content',
        Value: 'go_register',
      })
      return handleSubmit(ev)
    },
    [handleSubmit]
  )

  useReportAppScreen({
    SerialNumber: 1,
    Event: 'app_screen',
    Trigger: '註冊會員頁面',
    Parameters: 'page_title',
    Value: 'register_page',
  })

  return (
    <PageWrapperNonScroll>
      <HeaderWrap>
        <HeaderBox
          headerTitle={'注册会员'}
          onClose={({ close }) => {
            close()
            report({
              SerialNumber: 7,
              Event: 'register_click',
              Trigger: '返回',
              Parameters: 'content',
              Value: 'go_back',
            })
          }}
        />
      </HeaderWrap>
      <StyledPageScrollWrap noPadding>
        <ContentBox>
          <UserNameFieldBox
            name="user_name"
            placeholder={`请输入${PASSWORD_MIN_LENGTH}-${PASSWORD_MAX_LENGTH}位英文字母+数字的组合`}
            error={errors.user_name}
            handleChange={handleChange}
            onClick={() => {
              report({
                SerialNumber: 2,
                Event: 'register_click',
                Trigger: '點擊請輸入帳號',
                Parameters: 'content',
                Value: 'please_enter_account',
              })
            }}
          />

          <PasswordFieldBox
            name="password"
            placeholder={`请输入${PASSWORD_MIN_LENGTH}-${PASSWORD_MAX_LENGTH}位英文字母+数字的组合`}
            error={errors.password}
            handleChange={handleChange}
            onClick={() => {
              report({
                SerialNumber: 3,
                Event: 'register_click',
                Trigger: '點擊請輸入密碼',
                Parameters: 'content',
                Value: 'please_enter_password',
              })
            }}
          />

          <PasswordFieldBox
            name="confirm_password"
            placeholder={'再次输入密码'}
            error={errors.confirm_password}
            handleChange={handleChange}
            isConfirmPassword
            onClick={() => {
              report({
                SerialNumber: 4,
                Event: 'register_click',
                Trigger: '點擊請再輸入密碼',
                Parameters: 'content',
                Value: 'please_reenter_password',
              })
            }}
          />

          <BtnSubmit type="submit" disabled={state.isLoading} onClick={onClickSubmit}>
            {state.isLoading ? <BeatLoader size={12} margin={2} color={'#da9dc8'} /> : '立即注册'}
          </BtnSubmit>

          <BottomText>
            <span>已经有帐号</span>
            <BtnSwitchLogin onClick={openLoginModal}>去登入</BtnSwitchLogin>
          </BottomText>
        </ContentBox>
      </StyledPageScrollWrap>
    </PageWrapperNonScroll>
  )
}
