import { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectPreviewOrigin, selectOfficialOrigin } from 'redux/selector/app'
import { selectTokenInfo, selectUserInfo } from 'redux/selector/user'

import { fetchCountViewVideo } from 'api'

import { TRUE } from 'constant/common'
import { videoPaymentType } from 'constant/video'

import 'video.js/dist/video-js.css'
import './videojs-override.css'
import './videojs-component/vjs-custom-spinner.css'

import Loading from './component/Loading'
import {
  ButtonPurchase,
  ButtonWrapper,
  IconBack,
  Message,
  MutedIcon,
  MutedOverlay,
  PromptMessage,
  PromptMessageSkipArrow,
  UnmuteButton,
  VideoEndedWrapper,
  VideoWrapper,
} from './Styled'
import { addLoadingSpinner } from './videojs-component/LoadingSpinner'
import useOpenModal from './hooks/useOpenModal'
import classNames from 'classnames'
import { useOpenPurchaseVideoModal } from 'hooks/useOpenPurchaseVideoModel'
import { addTitleBar } from './videojs-component/vjs-title-bar'
import { addHeader } from './videojs-component/vjs-header'
import { addBackButton } from './videojs-component/vjs-back-button'
import { addMuteButton } from './videojs-component/vjs-mute-button'
import { detectUserAgent } from 'utils/browser'
import { fetchM3U8Url } from 'utils/videoUtil'
import { catchPromiseCancel } from 'utils/catchPromiseCancel'
import { addOldPlaybackRateMenuButton } from './videojs-component/vjs-old-playback-rate-menu-button'
import { useEvent } from 'hooks/useEvent'
import { addSeekBarVideo } from './videojs-component/vjs-seek-bar-video'

function nuzz() {}

// Video.registerComponent('LoadingSpinner', LoadingSpinner)
function installVideoJsComponents(Video) {
  addTitleBar(Video)
  addHeader(Video)
  addBackButton(Video)
  addMuteButton(Video)
  addLoadingSpinner(Video)
  addSeekBarVideo(Video)
  // video js 8 has menu popup, but we want to keep the video js 7 way
  addOldPlaybackRateMenuButton(Video)

  Video.setFormatTime((seconds, guide) => {
    seconds = seconds < 0 ? 0 : seconds
    let s = Math.floor(seconds % 60)
    let m = Math.floor((seconds / 60) % 60)
    let h = Math.floor(seconds / 3600)
    const gh = Math.floor(guide / 3600)
    // handle invalid times
    if (isNaN(seconds) || seconds === Infinity) {
      // '-' is false for all relational operators (e.g. <, >=) so this setting
      // will add the minimum number of fields specified by the guide
      h = m = s = '-'
    }
    // Check if we need to show hours
    h = h > 0 || gh > 0 ? (h < 10 ? '0' : '') + h + ':' : ''
    // If hours are showing, we may need to add a leading zero.
    // Always show at least one digit of minutes.
    m = (m < 10 ? '0' + m : m) + ':'
    // Check if leading zero is need for seconds
    s = s < 10 ? '0' + s : s

    return h + m + s
  })
}

const isPlayerLive = (player) => {
  return player != null && !player.isDisposed()
}

/** 元件 - 播放影片的 Player */
const VideoPlayer = ({
  videoInfo,
  poster,
  skipBigPlayIcon = false,
  playerPageUuid,
  onClickPlay,
  onClickPause,
  onUserSeek,
  onClickFullScreen,
  onClickShowUi,
  onClickHideUi,
  onPlay: onPlayParent,
  onPause: onPauseParent,
  onEnded: onEndedParent,
  onBack = () => {},
  ...props
}) => {
  const VideoRef = useRef()
  const tokenInfo = useSelector(selectTokenInfo)
  const userInfo = useSelector(selectUserInfo)
  const previewOrigin = useSelector(selectPreviewOrigin)
  const officialOrigin = useSelector(selectOfficialOrigin)
  const { openEnableVipModal } = useOpenModal({ videoInfo })
  const openPurchaseVideoModal = useOpenPurchaseVideoModal({ videoInfo })
  const [activeVideoEndedWrapper, setActiveVideoEndedWrapper] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const isVIP = userInfo.is_vip === TRUE
  const isBuyVideo = videoInfo.buy_video === TRUE

  const videoId = videoInfo.id

  /** 預覽影片路徑 */
  const previewVideoPath = previewOrigin ? previewOrigin?.concat(videoInfo.preview_url) : ''
  /** 高清影片路徑 */
  const officialVideoPath = officialOrigin ? officialOrigin?.concat(videoInfo.highres_url) : ''

  const payment_type = videoInfo.payment_type

  /** 影片類型為金幣, 且用戶沒有買過影片 */
  const isCoinVideoNeverBuy = payment_type === videoPaymentType.COIN && !isBuyVideo

  /** 影片類型為VIP, 但用戶非VIP */
  const isVipVideoNotVip = payment_type === videoPaymentType.VIP && !isVIP

  const [mutedOverlay, setMutedOverlay] = useState(false)

  /** 如果影片類型為免費, 或 影片類型為金幣購買且用戶已買過此影片, 或影片類型VIP且用戶為VIP */
  const isUseOfficial =
    payment_type === videoPaymentType.FREE ||
    (payment_type === videoPaymentType.COIN && isBuyVideo) ||
    (payment_type === videoPaymentType.VIP && isVIP)

  const url = isUseOfficial ? officialVideoPath : previewVideoPath

  const [player, setPlayer] = useState(/** @type {ReturnType<(typeof import('video.js'))['default']> | null} */ (null))
  const [loadingPlayer, setLoadingPlayer] = useState(
    /** @type {ReturnType<(typeof import('video.js'))['default']> | null} */ (null)
  )

  const onSkipPreview = () => {
    // setActiveVideoEndedWrapper(false);
    if (player) {
      player.pause()
      player.currentTime(player.duration())
      setActiveVideoEndedWrapper(true)
    }
  }

  const isiOS = !!window.navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

  // const unsubscribe = () => {
  //   /** 重置封面圖片 */
  //   PlayerRef.current.poster("");

  //   /** 重置事件 */
  //   PlayerRef.current?.off("loadstart");
  //   PlayerRef.current?.off("play");
  //   PlayerRef.current?.off("ended");
  //   PlayerRef.current?.off("loadedmetadata");
  // };

  // create player
  useLayoutEffect(() => {
    // this is a hack due to video js removing element directly and crash react
    /** @type {HTMLVideoElement} */
    const targetProto = VideoRef.current
    /** @type {HTMLElement} */
    const realTarget = targetProto.cloneNode(true)
    targetProto.style.display = 'none'
    targetProto.parentNode.insertBefore(realTarget, targetProto)

    let aborted = false
    let newPlayer

    async function load() {
      const Video = (await import('video.js')).default
      if (aborted) return
      installVideoJsComponents(Video)
      const agent = detectUserAgent()
      const muted = agent.isIos
      newPlayer = Video(
        realTarget,
        {
          // /** 封面圖片 */
          // poster: `data:image/jpeg;base64, ${previewImage}`,
          html5: {
            vhs: {
              /** https://github.com/videojs/videojs-contrib-hls/issues/665 */
              overrideNative: true,
              cacheEncryptionKeys: true,
            },
            nativeAudioTracks: false,
            nativeVideoTracks: false,
          },
          controls: true,
          preload: 'auto',
          autoplay: true,
          muted: muted,
          inactivityTimeout: 0,
          playbackRates: [0.5, 1, 1.5, 2],
          children: {
            mediaLoader: {},
            posterImage: {},
            textTrackDisplay: {},
            loadingSpinner: {},
            bigPlayButton: {},
            liveTracker: {},
            header: {},
            titleBar: {},
            backButton: {},
            controlBar: {
              children: {
                playToggle: {},
                currentTimeDisplay: {},
                timeDivider: {},
                progressControl: {
                  children: ['seekBarVideo'],
                },
                liveDisplay: {},
                seekToLive: {},
                durationDisplay: {},
                customControlSpacer: {},
                oldPlaybackRateMenuButton: {},
                muteButton: {},
                chaptersButton: {},
                descriptionsButton: {},
                subsCapsButton: {},
                audioTrackButton: {},
                fullscreenToggle: {},
              },
            },
            errorDisplay: {},
            textTrackSettings: {},
            resizeManager: {},
          },
        },
        () => {}
      )

      newPlayer.el().querySelector('video')?.setAttribute('webkit-playsinline', '')

      newPlayer.el().querySelector('video')?.setAttribute('playsinline', '')

      setLoadingPlayer(newPlayer)
    }

    load()

    return () => {
      aborted = true
      if (newPlayer) {
        newPlayer.dispose()
      } else {
        realTarget.remove()
      }
      targetProto.style.display = ''
    }
  }, [])

  useEffect(() => {
    if (!isPlayerLive(loadingPlayer)) {
      return
    }

    const readyHandler = () => {
      setPlayer(loadingPlayer)
    }

    loadingPlayer.ready(readyHandler)

    return () => {
      if (!loadingPlayer.isDisposed()) {
        loadingPlayer.off('ready', readyHandler)
      }
    }
  }, [loadingPlayer])

  /** 封面圖片 */
  useEffect(() => {
    if (!isPlayerLive(player)) {
      return
    }
    player.poster(poster)
  }, [player, poster])

  /** 移除播放按鈕 */
  useEffect(() => {
    if (!isPlayerLive(player)) {
      return
    }
    if (skipBigPlayIcon) {
      player.addClass('vjs-has-started')
    }
  }, [player, skipBigPlayIcon])

  // 設定影片網址
  useEffect(() => {
    if (!isPlayerLive(player) || !url) {
      return
    }

    const controller = new AbortController()
    const { signal } = controller

    fetchM3U8Url(url, videoId, tokenInfo, signal)
      .then((url) => {
        player.src({
          type: 'application/x-mpegURL',
          src: url,
        })
        player.load()
      })
      .catch(catchPromiseCancel)

    return () => {
      controller.abort()
    }
  }, [player, isUseOfficial, isiOS, officialOrigin, tokenInfo.access_token, url, videoId, tokenInfo])

  // 讀取狀態：開始讀取
  // android... etc workaround
  // useEffect(() => {
  //   if (!isPlayerLive(player)) {
  //     return
  //   }

  //   const onLoadStart = function (e) {
  //     /** 讀取中... */
  //     setIsLoading(true)

  //     if (skipBigPlayIcon) {
  //       player.addClass('vjs-has-started')
  //     }

  //     /** 如果不是macOs系統的話才需要這樣處理 */
  //     if (!isiOS) {
  //       player.tech().vhs.xhr.beforeRequest = function (options) {
  //         if (isUseOfficial) {
  //           // 處理高清影片
  //           const extensionRegexp = new RegExp(/\.[0-9a-z]+$/, 'i')
  //           const extension = options.uri.match(extensionRegexp)?.[0]

  //           if (extension === '.key' || options.uri.startsWith('key://')) {
  //             const fetchKeyApi =
  //               process.env.NODE_ENV === 'production' ? window.location.origin?.concat(getAPI()) : getAPI()

  //             options.uri = processURL(fetchKeyApi.concat(`/video/key/${videoId}`))

  //             options.headers = {
  //               AuthDog: `${tokenInfo.token_type} ${tokenInfo.access_token}`,
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  //   player.on('loadstart', onLoadStart)
  //   return () => {
  //     player.off('loadstart', onLoadStart)
  //   }
  // }, [player, isUseOfficial, isiOS, poster, skipBigPlayIcon, tokenInfo.access_token, tokenInfo.token_type, videoId])

  // 讀取狀態：完成讀取
  useEffect(() => {
    if (!isPlayerLive(player)) {
      return
    }
    /** 數據是否已讀取完成, 不要用 canplaythrough, safari 不支援  */
    const onLoadedMetadata = function (e) {
      setIsLoading(false)
      if (player.muted()) {
        setMutedOverlay(true)
      }
      player.play().catch((error) => {
        console.error('error on play', error)
      })
    }
    player.on('loadedmetadata', onLoadedMetadata)
    return () => {
      player.off('loadedmetadata', onLoadedMetadata)
    }
  }, [player])

  useEffect(() => {
    if (!isPlayerLive(player)) {
      return
    }
    /** 播放結束 */
    const onEnd = function () {
      if (isCoinVideoNeverBuy || isVipVideoNotVip) {
        setActiveVideoEndedWrapper(true)
      }
    }
    player.on('ended', onEnd)
    return () => {
      player.off('ended', onEnd)
    }
  }, [player, isCoinVideoNeverBuy, isVipVideoNotVip])

  // do it once per player
  const onPlayParentEvent = useEvent(onPlayParent)
  useEffect(() => {
    if (!isPlayerLive(player)) {
      return
    }

    const onPlay = function (ev) {
      fetchCountViewVideo({ video_id: videoId })
      onPlayParentEvent(ev)
    }

    /** 播放開始 計算觀看數 */
    player.one('play', onPlay)
    return () => {
      player.off('play', onPlay)
    }
  }, [onPlayParentEvent, player, videoId])

  // do it once per player
  const onPauseParentEvent = useEvent(onPauseParent) ?? nuzz
  useEffect(() => {
    if (!isPlayerLive(player)) {
      return
    }

    /** 播放開始 計算觀看數 */
    player.one('pause', onPauseParentEvent)
    return () => {
      player.off('pause', onPauseParentEvent)
    }
  }, [onPauseParentEvent, player])

  // do it once per player
  const onEndedParentEvent = useEvent(onEndedParent) ?? nuzz
  useEffect(() => {
    if (!isPlayerLive(player)) {
      return
    }

    /** 播放開始 計算觀看數 */
    player.one('ended', onEndedParentEvent)
    return () => {
      player.off('ended', onEndedParentEvent)
    }
  }, [onEndedParentEvent, player])

  // set title
  useEffect(() => {
    if (!isPlayerLive(player)) {
      return
    }
    const titleBar = player?.getChild('TitleBar')
    if (titleBar) {
      titleBar.setTitle(videoInfo.title)
    }
  }, [player, videoInfo.title])

  // add exit handler
  useEffect(() => {
    if (!isPlayerLive(player)) {
      return
    }
    const backButton = player?.getChild('BackButton')
    if (backButton) {
      const handler = () => {
        onBack?.()
      }
      backButton.on('click', handler)
      return () => {
        backButton.off('click', handler)
      }
    }
  }, [onBack, player])

  // add play button event handler
  const onClickPlayEvent = useEvent(onClickPlay)
  const onClickPauseEvent = useEvent(onClickPause)
  useEffect(() => {
    if (!isPlayerLive(player)) {
      return
    }
    const playToggle = player?.getChild('ControlBar')?.getChild('playToggle')
    if (playToggle) {
      const handler = (ev) => {
        if (player.paused()) {
          onClickPlayEvent?.(ev)
        } else {
          onClickPauseEvent?.(ev)
        }
      }
      playToggle.on('pointerdown', handler)
      return () => {
        playToggle.off('pointerdown', handler)
      }
    }
  }, [onClickPauseEvent, onClickPlayEvent, player])

  // add user seek event handler
  const onUserSeekEvent = useEvent(onUserSeek)
  useEffect(() => {
    if (!isPlayerLive(player)) {
      return
    }
    const handler = (ev) => {
      onUserSeekEvent(ev)
    }
    console.log(player)
    player.on('userseek', handler)
    return () => {
      player.off('userseek', handler)
    }
  }, [onUserSeekEvent, player])

  // add fullscreen btn event handler
  const onClickFullScreenEvent = useEvent(onClickFullScreen)
  useEffect(() => {
    if (!isPlayerLive(player)) {
      return
    }
    const handler = (ev) => {
      if (!player.isFullscreen()) {
        onClickFullScreenEvent(ev)
      }
    }
    const fullScreenButton = player.getChild('ControlBar')?.getChild('fullscreenToggle')
    fullScreenButton.on('pointerdown', handler)
    return () => {
      fullScreenButton.off('pointerdown', handler)
    }
  }, [onClickFullScreenEvent, player])

  // ui toggle event
  const onClickShowUiEvent = useEvent(onClickShowUi)
  const onClickHideUiEvent = useEvent(onClickHideUi)
  useEffect(() => {
    if (!isPlayerLive(player)) {
      return
    }
    const handler = (ev) => {
      if (player.userActive()) {
        onClickHideUiEvent(ev)
      } else {
        onClickShowUiEvent(ev)
      }
    }
    const videoEl = player.tech(process.env.NODE_ENV !== 'development').el()
    if (videoEl) {
      videoEl.addEventListener('pointerdown', handler)
      return () => {
        videoEl.addEventListener('pointerdown', handler)
      }
    }
  }, [onClickHideUiEvent, onClickShowUiEvent, player])

  const onClickMutedOverlay = useCallback(() => {
    player?.muted(false)
    setMutedOverlay(false)
  }, [player])

  return (
    <VideoWrapper {...props}>
      <video-js
        ref={VideoRef}
        // id={"video-player"}
        className="vjs-default-skin"
        style={{ height: '100%', width: '100%' }}
      ></video-js>
      {isCoinVideoNeverBuy && (
        <PromptMessage onClick={onSkipPreview}>
          跳过预览
          <PromptMessageSkipArrow />
        </PromptMessage>
      )}

      {isVipVideoNotVip && (
        <PromptMessage onClick={onSkipPreview}>
          跳过预览
          <PromptMessageSkipArrow />
        </PromptMessage>
      )}
      <MutedOverlay className={mutedOverlay ? 'active' : ''} onClick={onClickMutedOverlay}>
        <UnmuteButton>
          <MutedIcon />
          取消静音
        </UnmuteButton>
        <IconBack onClick={onBack} />
      </MutedOverlay>

      <VideoEndedWrapper
        className={classNames({ active: (isCoinVideoNeverBuy || isVipVideoNotVip) && activeVideoEndedWrapper })}
      >
        {isCoinVideoNeverBuy && <Message>试看结束，金币解锁观看完整版</Message>}
        {isVipVideoNotVip && <Message>试看结束，升级VIP观看完整版</Message>}

        <ButtonWrapper>
          {isCoinVideoNeverBuy && <ButtonPurchase onClick={openPurchaseVideoModal}>购买视频</ButtonPurchase>}
          {isVipVideoNotVip && <ButtonPurchase onClick={openEnableVipModal}>开通VIP</ButtonPurchase>}
        </ButtonWrapper>
        <IconBack onClick={onBack} />
      </VideoEndedWrapper>

      {/* 读取动画 */}
      {isLoading && <Loading onBack={onBack} />}
    </VideoWrapper>
  )
}

export default VideoPlayer
