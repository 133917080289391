import { memo, useMemo, useEffect, useState, useCallback } from 'react'
//
import { ENABLED, TRUE } from 'constant/common'
//
import { fetchVideoTagListWithParam, fetchVideoTopicList } from 'api'
import { ImageBox, ImageWrapper, StyledAreaTitle } from './Styled'
import { useNavigateTo } from 'hooks/useNavigateTo'
import { catchPromiseCancel } from 'utils/catchPromiseCancel'
import { report } from 'hooks/useAnalytics'

function RecommendTagList({ mode = 'video' }) {
  const [tagList, setTagList] = useState([])
  const { navigateToViewMore } = useNavigateTo()

  const type = useMemo(() => {
    if (mode === 'video' || mode === 'short') return 1
    else if (mode === 'comic') return 2
    return 0
  }, [mode])

  const recommendTagList = useMemo(() => {
    if (mode === 'video') return tagList?.filter((e) => e.status === ENABLED && e.promote === TRUE && e.level === 2)
    else return tagList?.filter((e) => e.status === ENABLED && e.promote === TRUE)
  }, [mode, tagList])

  const onTagItemClick = useCallback(
    (item) => {
      report({
        SerialNumber: 9,
        Event: 'search_click',
        Trigger: '點擊推薦搜尋',
        Parameters: 'recommended_search_id',
        Value: item.tagId,
      })
      const tagId = item.id
      navigateToViewMore({
        params:
          mode !== 'short'
            ? {
                tag_ids: tagId,
              }
            : {
                topic_ids: tagId,
              },
        showAd: false,
        headerTitle: item?.name,
        type: mode,
      })
    },
    [mode, navigateToViewMore]
  )

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const load = async () => {
      if (mode === 'short') {
        const tags = await fetchVideoTopicList({ promote: 1, signal }).catch(catchPromiseCancel)
        if (signal.aborted) return
        setTagList([...tags.data])
      } else {
        const tags = await fetchVideoTagListWithParam({ type, promote: 1, signal }).catch(catchPromiseCancel)
        if (signal.aborted) return
        setTagList([...tags.data])
      }
    }
    load()
    return () => {
      controller.abort()
    }
  }, [mode, type])

  if (!recommendTagList?.length) return null

  return (
    <>
      <StyledAreaTitle text="搜索推荐" />
      <ImageWrapper>
        {recommendTagList?.map((item, i) => (
          <ImageBox src={item.icon_path} vertical={mode !== 'video'} onClick={() => onTagItemClick(item)}></ImageBox>
        ))}
      </ImageWrapper>
    </>
  )
}

export default memo(RecommendTagList)
