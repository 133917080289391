import { LogEntry } from 'hooks/useAnalytics'

export const NAV_ANALYTIC_PARAMS: Partial<Record<'' | 'app-center' | 'profile' | 'game', Partial<LogEntry>>> = {
  '': {
    SerialNumber: 4,
    Event: 'application_click',
  },
  profile: {
    SerialNumber: 12,
    Event: 'account_click',
  },
  game: {
    SerialNumber: 11,
    Event: 'chess_game_click',
  },
}
