import { useState, useRef, useMemo, useEffect } from 'react'
//
import { patchGamePayPin } from 'api/index'

import { FormErrorText, SubmitButton, TextCenter, Wrapper } from './Styled'

import InputRow from './component/InputRow'
import { report, useReportAppScreen } from 'hooks/useAnalytics'

export default function WithdrawCode({ onSuccess }) {
  const [pin1, setPin1] = useState('')
  const [pin2, setPin2] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const firstRowRef = useRef(null)
  const secondRowRef = useRef(null)
  const formInvalid = useMemo(() => {
    return !pin1 || pin1.length < 4 || pin2.length < 4
  }, [pin1, pin2])

  function validate() {
    let message = ''
    if (!pin1 || pin1.length < 4) message = '请输入取款密码'
    else if (pin1 != pin2) message = '取款密码不一致'
    setErrorMessage(message)
    return message
  }

  async function handleSubmit() {
    report({
      SerialNumber: 4,
      Event: 'setup_withdrawal_password_click',
      Trigger: '點擊確定送出',
      Parameters: 'content',
      Value: 'confirm_submit',
    })
    setErrorMessage('')
    const payload = {
      pin: pin1,
    }
    if (loading) return
    if (validate()) return // 有錯誤時拒絕提交
    try {
      setLoading(true)
      await patchGamePayPin(payload)
      onSuccess()
    } catch (ex) {
      setErrorMessage(ex.message)
    } finally {
      setLoading(false)
    }
  }

  function handleFirstRowFinish() {
    if (secondRowRef.current) {
      secondRowRef.current.focus()
    }
  }

  useEffect(() => {
    if (firstRowRef.current) {
      firstRowRef.current.focus()
    }
  }, [])

  useReportAppScreen({
    SerialNumber: 1,
    Event: 'app_screen',
    Trigger: '設置取款密碼頁面',
    Parameters: 'page_title',
    Value: 'setup_withdrawal_password_page',
  })

  return (
    <Wrapper>
      <InputRow
        label="请输入取款密码"
        icon="/account/icon-password.svg"
        ref={firstRowRef}
        onInput={(v) => setPin1(v)}
        onFinish={handleFirstRowFinish}
        onClick={() => {
          report({
            SerialNumber: 2,
            Event: 'setup_withdrawal_password_click',
            Trigger: '點擊請輸入取款密碼',
            Parameters: 'content',
            Value: 'please_enter_withdrawal_password',
          })
        }}
      ></InputRow>
      <InputRow
        label="请再次确认取款密码"
        icon="/account/icon-unlock.svg"
        ref={secondRowRef}
        onInput={(v) => setPin2(v)}
        onClick={() => {
          report({
            SerialNumber: 3,
            Event: 'setup_withdrawal_password_click',
            Trigger: '點擊請再次確認取款密碼',
            Parameters: 'content',
            Value: 'please_confirm_withdrawal_password',
          })
        }}
      ></InputRow>
      <FormErrorText>{errorMessage}</FormErrorText>
      <TextCenter>
        <SubmitButton onClick={handleSubmit} disabled={formInvalid}>
          确定送出
        </SubmitButton>
      </TextCenter>
    </Wrapper>
  )
}
