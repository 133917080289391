import { useEffect, useState, useMemo } from 'react'

import { getLandingDomains } from 'api'

import HeaderBox from 'component/PageModalHeaderBox'

import {
  Content,
  Wrapper,
  ContentTitle,
  TitleImg,
  QRCodeWrapper,
  ContentLink,
  CopyButton,
  DownloadButton,
  ContentWrapper,
  HeaderWrapper,
  ContentItems,
  ScrollContainer,
} from './Styled'
import { PageWrapperNonScroll } from 'commonStyledComponents'
import { usePageActivated } from 'app-layered-layout/usePageActivated'
import QRCode from 'qrcode.react'
import { BeatLoader } from 'react-spinners'
import { useToastAlert } from 'hooks/useToastAlert'
import PageLoading from 'component/Loading/PageLoading'
import useOpenModal from './hooks/useOpenModal'
import { report, useReportAppScreen } from 'hooks/useAnalytics'

export default function SharePage() {
  const pageActivated = usePageActivated()
  const { toast } = useToastAlert()
  const [landingPage, setLandingPage] = useState(null)
  const [loading, setLoading] = useState(true)
  const { openShareCard } = useOpenModal()

  const link = useMemo(() => {
    return landingPage ? landingPage.domain_name : ''
  }, [landingPage])

  async function handleCopy() {
    report({
      SerialNumber: 2,
      Event: 'promotional_sharing_click',
      Trigger: '點擊复制分享链接',
      Parameters: 'content',
      Value: 'copy_share_link',
    })
    try {
      if (link && navigator.clipboard) {
        await navigator.clipboard.writeText(link)
        toast('已复制链接成功')
      }
    } catch (ex) {
      console.warn('copy text error', ex)
    }
  }

  async function handleDownload() {
    report({
      SerialNumber: 3,
      Event: 'promotional_sharing_click',
      Trigger: '點擊保存图片分享',
      Parameters: 'content',
      Value: 'save_image_share',
    })
    openShareCard(link)
  }

  async function loadPage(signal) {
    try {
      setLoading(true)
      const res = await getLandingDomains({ signal, user_name_eq: 'share' })
      if (res.data.length) {
        const url = res.data.find((m) => m.domain_name)
        setLandingPage(url)
      }
    } catch {
      console.log('fetch url error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!pageActivated) {
      return
    }
    const controller = new AbortController()
    const signal = controller.signal
    loadPage(signal)
    return () => {
      controller.abort()
    }
  }, [pageActivated])

  useReportAppScreen({
    SerialNumber: 1,
    Event: 'app_screen',
    Trigger: '推廣分享頁面',
    Parameters: 'page_title',
    Value: 'promotional_sharing_page',
  })

  return (
    <PageWrapperNonScroll>
      <Wrapper>
        <HeaderWrapper>
          <HeaderBox
            headerTitle={''}
            onClose={({ close }) => {
              close()
              report({
                SerialNumber: 4,
                Event: 'promotional_sharing_click',
                Trigger: '返回',
                Parameters: 'content',
                Value: 'go_back',
              })
            }}
          />
        </HeaderWrapper>
        {!loading ? (
          <ScrollContainer>
            <TitleImg></TitleImg>
            <ContentWrapper>
              <Content>
                <ContentItems>
                  <ContentTitle>扫描二维码 下载APP立即观看</ContentTitle>
                  <QRCodeWrapper>
                    {!!link ? (
                      <QRCode id={'qrcode'} style={{ width: '2.64rem', height: '2.64rem' }} value={link} level={'H'} />
                    ) : (
                      <BeatLoader size={10} />
                    )}
                  </QRCodeWrapper>
                  <div>如果在微信扫码不能打开, 手动</div>
                  <div>在浏览器输入推广链结打开</div>
                  <ContentLink>{link}</ContentLink>
                  <CopyButton onClick={handleCopy}>复制分享链接</CopyButton>
                  <DownloadButton onClick={handleDownload}>保存图片分享</DownloadButton>
                </ContentItems>
              </Content>
            </ContentWrapper>
          </ScrollContainer>
        ) : (
          <PageLoading></PageLoading>
        )}
      </Wrapper>
    </PageWrapperNonScroll>
  )
}
