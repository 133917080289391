import { forwardRef, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react'
import { PlayerLoadingIndicator, PlayerLoadingIndicatorInner, PlayerWrapper, StyledVideoJs } from './Styled'
// import { default as Video } from 'video.js'
// import '@videojs/http-streaming'
import { useSelector } from 'react-redux'
import { selectTokenInfo, selectUserInfo } from 'redux/selector/user'
import { selectOfficialOrigin, selectPreviewOrigin } from 'redux/selector/app'
import { TRUE } from 'constant/common'
import { fetchCountViewVideo } from 'api'
import { videoPaymentType } from 'constant/video'
import './videojs-components/vjs-mobile-control-layer.css'
import { addMobileControlLayer } from './videojs-components/MobileControlLayer'
import { processURL } from 'utils/debugUtil'
// import { getAPI } from 'utils/settings'
import { useHasPayment } from 'hooks/useHasPayment'
import { addEndCard } from './videojs-components/vjs-end-card'
import { useNavigateTo } from 'hooks/useNavigateTo'
import { useOpenPurchaseVideoModal } from 'hooks/useOpenPurchaseVideoModel'
import { fetchM3U8Url } from 'utils/videoUtil'
import { addSeekCard } from './videojs-components/SeekCard'
import { catchPromiseCancel } from 'utils/catchPromiseCancel'
import { useEvent } from 'hooks/useEvent'

const PRE_RENDER_CLASS_NAME = 'pre-render'

const isPlayerLive = (player) => {
  return player != null && !player.isDisposed()
}

let VideoJSModule

const ShortPlayer = forwardRef(
  (
    /**
     * @type {{
     *   videoInfo: import("../../../types/api").SchemaShortVideoShow
     * }}
     */ { videoInfo, onDoubleCLick, onLongClick, onClickPause, onClickPlay, onUserSeek, ...props },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      currentTime: (second) => {
        if (player) {
          player.currentTime(second)
        }
      },
      pause: () => {
        if (player) {
          player.pause()
        }
      },
      play: () => {
        if (player) {
          player.play()
        }
      },
      muted: (bool) => {
        if (player) {
          player.muted(bool)
        }
      },
    }))
    const playerElementRef = useRef()
    const doubleClickHandlerRef = useRef()

    const tokenInfo = useSelector(selectTokenInfo)
    const userInfo = useSelector(selectUserInfo)
    const previewOrigin = useSelector(selectPreviewOrigin)
    const officialOrigin = useSelector(selectOfficialOrigin)
    const [isLoading, setIsLoading] = useState(true)
    const { navigateToEnableVip } = useNavigateTo()
    const openPurchaseVideoModal = useOpenPurchaseVideoModal({ videoInfo, type: 'short' })

    const isVIP = userInfo.is_vip === TRUE
    const isBuyVideo = videoInfo.buy_video === TRUE

    const videoId = videoInfo.id

    const hasPayment = useHasPayment()

    /** 預覽影片路徑 */
    const previewVideoPath = previewOrigin?.concat(videoInfo.preview_url)
    /** 高清影片路徑 */
    const officialVideoPath = officialOrigin?.concat(videoInfo.highres_url)

    const payment_type = videoInfo.payment_type
    const product_coin = videoInfo.product_coin

    // /** 影片類型為金幣, 且用戶沒有買過影片 */
    // const isCoinVideoNeverBuy =
    //   payment_type === videoPaymentType.COIN && !isBuyVideo;

    // /** 影片類型為VIP, 但用戶非VIP */
    // const isVipVideoNotVip = payment_type === videoPaymentType.VIP && !isVIP;

    /** 如果影片類型為免費, 或 影片類型為金幣購買且用戶已買過此影片, 或影片類型VIP且用戶為VIP */
    const isUseOfficial =
      payment_type === videoPaymentType.FREE ||
      (payment_type === videoPaymentType.COIN && isBuyVideo) ||
      (payment_type === videoPaymentType.VIP && isVIP)

    const needPayment = !isUseOfficial

    const url = isUseOfficial ? officialVideoPath : previewVideoPath

    const isiOS = !!window.navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

    const [player, setPlayer] = useState(/** @type {import('video.js').VideoJsPlayer | null} */ (null))
    const [loadingPlayer, setLoadingPlayer] = useState(/** @type {import('video.js').VideoJsPlayer | null} */ (null))

    // preload
    import('video.js')

    const onClickPauseEvent = useEvent(onClickPause)
    const onClickPlayEvent = useEvent(onClickPlay)

    // create player
    useLayoutEffect(() => {
      // this is a hack due to video js removing element directly and crash react
      /** @type {HTMLVideoElement} */
      const targetProto = playerElementRef.current
      /** @type {HTMLElement} */
      const realTarget = targetProto.cloneNode(true)
      targetProto.style.display = 'none'
      targetProto.parentNode.insertBefore(realTarget, targetProto)

      let newPlayer
      let aborted = false

      async function load() {
        const Video = VideoJSModule || (VideoJSModule = (await import('video.js')).default)
        if (aborted) return
        addEndCard(Video)
        addMobileControlLayer(Video)
        addSeekCard(Video)

        const newPlayer = Video(
          realTarget,
          {
            // /** 封面圖片 */
            // poster: `data:image/jpeg;base64, ${previewImage}`,
            html5: {
              vhs: {
                /** https://github.com/videojs/videojs-contrib-hls/issues/665 */
                overrideNative: true,
                cacheEncryptionKeys: true,
              },
              nativeAudioTracks: false,
              nativeVideoTracks: false,
            },
            controls: true,
            autoplay: true,
            loop: !needPayment,
            preload: 'auto',
            poster: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
            userActions: {
              doubleClick(ev) {
                // alert('test')
                // console.log('double clicked')
                doubleClickHandlerRef.current?.(ev)
              },
              click() {
                // console.log('clicked')
                if (newPlayer.paused()) {
                  newPlayer.play()
                  onClickPlayEvent?.()
                } else {
                  newPlayer.pause()
                  onClickPauseEvent?.()
                }
              },
            },
            children: {
              mediaLoader: {},
              posterImage: {},
              textTrackDisplay: {},
              loadingSpinner: {},
              // bigPlayButton: {},
              liveTracker: {},
              controlBar: {
                children: [
                  // 'playToggle',
                  // 'currentTimeDisplay',
                  // 'timeDivider',
                  'progressControl',
                  // 'liveDisplay',
                  // 'seekToLive',
                  // 'durationDisplay',
                  // 'customControlSpacer',
                  // 'playbackRateMenuButton',
                  // 'volumePanel',
                  // 'chaptersButton',
                  // 'descriptionsButton',
                  // 'subsCapsButton',
                  // 'audioTrackButton',
                  // 'fullscreenToggle'
                ],
              },
              errorDisplay: {},
              textTrackSettings: {},
              resizeManager: {},
              mobileControlLayer: {},
              endCard: {},
            },
          },
          () => {}
        )

        newPlayer.addChild('SeekCard')
        // 避免 virtual page 滑動行為
        const controlBar = newPlayer.el().querySelector('.vjs-control-bar')
        if (controlBar?.className && !controlBar.className.includes('block-virtual-page'))
          controlBar.className += ' block-virtual-page'

        newPlayer.el().querySelector('video')?.setAttribute('webkit-playsinline', '')

        newPlayer.el().querySelector('video')?.setAttribute('playsinline', '')

        setLoadingPlayer(newPlayer)
      }

      load()

      return () => {
        aborted = true
        if (newPlayer) {
          newPlayer.dispose()
        } else {
          realTarget.remove()
        }
        targetProto.style.display = ''
      }
    }, [hasPayment, needPayment, onClickPauseEvent, onClickPlayEvent, payment_type])

    useEffect(() => {
      if (!isPlayerLive(loadingPlayer)) {
        return
      }

      const readyHandler = () => {
        setPlayer(loadingPlayer)
      }

      loadingPlayer.ready(readyHandler)

      return () => {
        if (!loadingPlayer.isDisposed()) {
          loadingPlayer.off('ready', readyHandler)
        }
      }
    }, [loadingPlayer])

    /** 移除播放按鈕 */
    useEffect(() => {
      if (!isPlayerLive(player)) {
        return
      }
      player.addClass('vjs-has-started')
    }, [player])

    /** 切換播放按鈕影片結束時的顯示 */
    useEffect(() => {
      if (!isPlayerLive(player)) {
        return
      }
      /**
       * @type {MobileControlLayer}
       */
      const layer = player.getChild('mobileControlLayer')
      layer.setEndScreenVisibility(!needPayment)
    }, [needPayment, player])

    // 設定影片網址
    useLayoutEffect(() => {
      if (!isPlayerLive(player)) {
        return
      }

      // player.load()

      if (!url) {
        // do nothing without url
      } else {
        const controller = new AbortController()
        const { signal } = controller

        let id
        let timeoutId

        const opacityResetHandler = (force = false) => {
          if (player.currentTime() < 0.2 && !force) return
          player.off('timeupdate', bound)

          // console.log('rm', player.el().getAttribute('class'))
          // console.log(player.currentHeight())
          // console.log(player.currentWidth())
          // console.log(player.currentTime())

          if (force) {
            player.el().classList.remove(PRE_RENDER_CLASS_NAME)
          } else {
            id = setTimeout(() => {
              player.el().classList.remove(PRE_RENDER_CLASS_NAME)
            }, 100)
          }
        }
        const bound = opacityResetHandler.bind(null, false)

        fetchM3U8Url(url, videoId, tokenInfo, signal)
          .then((url) => {
            if (signal.aborted) return
            player.src({
              type: 'application/x-mpegURL',
              src: processURL(url),
            })
            player.load()
            player.on('timeupdate', bound)
          })
          .catch(catchPromiseCancel)

        player.el().classList.add(PRE_RENDER_CLASS_NAME)
        // console.log('add', player.el().getAttribute('class'))

        return () => {
          controller.abort()
          clearTimeout(id)
          clearTimeout(timeoutId)
          opacityResetHandler(true)
          player.off('timeupdate', bound)
        }
      }
    }, [player, isUseOfficial, isiOS, officialOrigin, tokenInfo.access_token, url, videoId, tokenInfo])

    // 讀取狀態：開始讀取
    // android... etc workaround
    // useEffect(() => {
    //   if (!isPlayerLive(player)) {
    //     return
    //   }

    //   const onLoadStart = function (e) {
    //     /** 讀取中... */
    //     setIsLoading(true)

    //     /** 如果不是macOs系統的話才需要這樣處理 */
    //     if (!isiOS) {
    //       player.tech().vhs.xhr.beforeRequest = function (options) {
    //         if (isUseOfficial) {
    //           // 處理高清影片
    //           const extensionRegexp = new RegExp(/\.[0-9a-z]+$/, 'i')
    //           const extension = options.uri.match(extensionRegexp)?.[0]

    //           if (extension === '.key' || options.uri.startsWith('key://')) {
    //             const fetchKeyApi =
    //               process.env.NODE_ENV === 'production' ? window.location.origin?.concat(getAPI()) : getAPI()

    //             options.uri = processURL(fetchKeyApi.concat(`/video/key/${videoId}`))

    //             options.headers = {
    //               AuthDog: `${tokenInfo.token_type} ${tokenInfo.access_token}`,
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    //   player.on('loadstart', onLoadStart)
    //   return () => {
    //     player.off('loadstart', onLoadStart)
    //   }
    // }, [player, isUseOfficial, isiOS, tokenInfo.access_token, tokenInfo.token_type, videoId])

    // 讀取狀態：完成讀取
    useEffect(() => {
      if (!isPlayerLive(player)) {
        return
      }
      /** 數據是否已讀取完成, 不要用 canplaythrough, safari 不支援  */
      const onLoadedMetadata = function (e) {
        setIsLoading(false)
      }
      player.on('loadedmetadata', onLoadedMetadata)
      return () => {
        player.off('loadedmetadata', onLoadedMetadata)
      }
    }, [player])

    const onPurchase = useCallback(() => {
      if (payment_type === videoPaymentType.VIP) {
        navigateToEnableVip({})
      }
      if (payment_type === videoPaymentType.COIN) {
        openPurchaseVideoModal()
      }
    }, [navigateToEnableVip, openPurchaseVideoModal, payment_type])

    // 設定購買介面
    useEffect(() => {
      if (!isPlayerLive(player)) {
        return
      }

      /**
       * @type { import('./videojs-components/vjs-end-card').EndCard}
       */
      const endCard = player.getChild('EndCard')

      if (!needPayment) {
        endCard.setPaymentType(false, '')
      } else {
        if (payment_type === videoPaymentType.VIP) {
          endCard.setPaymentType(true, '升级VIP观看完整版')
        }
        if (payment_type === videoPaymentType.COIN) {
          endCard.setPaymentType(true, `${product_coin} 金币解锁完整版`)
        }
      }

      endCard.on('button-click', onPurchase)

      return () => {
        endCard.off('button-click', onPurchase)
      }
    }, [needPayment, onPurchase, payment_type, player, product_coin])

    const lastRecordId = useRef()
    // do it once per url
    useEffect(() => {
      if (!isPlayerLive(player)) {
        return
      }
      const onPlay = function () {
        if (lastRecordId.current === videoId) {
          return
        }
        lastRecordId.current = videoId
        fetchCountViewVideo({ video_id: videoId })
      }

      /** 播放開始 計算觀看數 */
      player.on('play', onPlay)
      return () => {
        player.off('play', onPlay)
      }
    }, [player, videoId])

    useEffect(() => {
      doubleClickHandlerRef.current = onDoubleCLick
    }, [onDoubleCLick])

    // userseek event
    const onUserSeekEvent = useEvent(onUserSeek)
    useEffect(() => {
      if (!isPlayerLive(player)) {
        return
      }

      // 拉動時間軸事件
      player.on('userseek', onUserSeekEvent)
      return () => {
        player.off('userseek', onUserSeekEvent)
      }
    }, [onUserSeekEvent, player])

    // long click event
    const onLongClickEvent = useEvent(onLongClick)
    useEffect(() => {
      if (!isPlayerLive(player)) {
        return
      }

      // 拉動時間軸事件
      player.on('longclick', onLongClickEvent)
      return () => {
        player.off('longclick', onLongClickEvent)
      }
    }, [onLongClickEvent, player])

    return (
      <PlayerWrapper {...props} className={isLoading ? 'loading ' + (props.className ?? '') : props.className ?? ''}>
        <StyledVideoJs className="vjs-default-skin vjs-big-play-centered" ref={playerElementRef} />
        <PlayerLoadingIndicator>
          <PlayerLoadingIndicatorInner></PlayerLoadingIndicatorInner>
        </PlayerLoadingIndicator>
      </PlayerWrapper>
    )
  }
)

export default ShortPlayer
