import type videoJs from 'video.js'
import type Component from 'video.js/dist/types/component'
import type { default as ISeekBar } from 'video.js/dist/types/control-bar/progress-control/seek-bar'
type VideoJs = typeof videoJs

export function addSeekBarVideo(Video: VideoJs): typeof Component {
  const SeekBar = Video.getComponent('SeekBar') as unknown as typeof ISeekBar

  class SeekBarVideo extends SeekBar {
    handleMouseMove(event: PointerEvent): void {
      let newTime = this.calculateDistance(event) * this.player()!.duration()!
      if (newTime === this.player().duration()) {
        newTime = newTime - 0.1
      }

      this.player()
        .tech(process.env.NODE_ENV !== 'development')
        .el()
        .dispatchEvent(Object.assign(new PointerEvent('userseek', event), { to: newTime }))

      return super.handleMouseMove(event)
    }
  }

  Video.registerComponent('SeekBarVideo', SeekBarVideo)

  return SeekBarVideo
}
