import { useMemo, memo } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Autoplay, Pagination, Thumbs } from 'swiper/modules'
import { selectAdInfo } from 'redux/selector/app'
//
import { AdvertisementType } from 'constant/advertisement'
//
import CarouselItem from './component/CarouselItem'
import { SwiperWrapper } from './Styled'
import { report } from 'hooks/useAnalytics'

function Carousel() {
  const adInfo = useSelector(selectAdInfo)
  const adItems = useMemo(() => {
    return adInfo?.[AdvertisementType.ViewVideoBanner]
  }, [adInfo])

  /** 自動切換延遲時間 */
  const delay = 1000 * (adItems?.[0]?.carousel_time || 2.5)

  if (isEmpty(adItems)) return null

  return (
    <SwiperWrapper>
      <Swiper
        modules={[Autoplay, Pagination, A11y, Thumbs]}
        autoplay={{
          delay,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        loop
      >
        {adItems?.map((info, i) => (
          <SwiperSlide key={i}>
            <CarouselItem
              {...info}
              onClick={() => {
                report({
                  SerialNumber: 17,
                  Event: 'video_play_click',
                  Trigger: '點擊播放頁面廣告',
                  Parameters: 'click_play_page_ad_id',
                  Value: info.id,
                })
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperWrapper>
  )
}

export default memo(Carousel)
