import { Image } from './Styled'
import { formatDate } from 'utils/timeFormat'
import { fetchPostAdEvent } from 'api'
import { useCallback } from 'react'

export default function CarouselItem({ onClick, ...info }) {
  const handleAdClick = useCallback(
    (ev) => {
      onClick?.(ev)
      fetchPostAdEvent({
        date: formatDate(new Date()),
        material_id: info.id,
        material_name: info.name,
        oholo_site_id: info.site_id,
        oholoer_id: info.oholoer_id,
      })
      window.open(info?.link, '_blank')
    },
    [info.id, info?.link, info.name, info.oholoer_id, info.site_id, onClick]
  )
  return <Image src={info.icon_path} onClick={handleAdClick} />
}
