import { useMemo } from 'react'
import { GAME, HOME_VIDEO, INDEX, PROFILE } from 'constant/routers'

export const useNavigationLocalData = () => {
  const navContent = useMemo(() => {
    return [
      {
        name: '首页',
        search: '',
        loose: false,
        imageName: '/icon/ic_home',
        dynamicIconActive: null,
        dynamicIconInactive: null,
        navLink: INDEX,
        id: '[home]',
      },
      {
        name: '视频',
        search: '',
        loose: true,
        imageName: '/icon/ic_video',
        dynamicIconActive: null,
        dynamicIconInactive: null,
        navLink: HOME_VIDEO,
        id: '[video]',
      },
      {
        name: '游戏',
        search: '',
        loose: false,
        imageName: '/icon/ic_game',
        dynamicIconActive: null,
        dynamicIconInactive: null,
        navLink: GAME,
        id: '[game]',
      },
      {
        name: '帐户',
        search: '',
        loose: false,
        imageName: '/icon/ic_account',
        dynamicIconActive: null,
        dynamicIconInactive: null,
        navLink: PROFILE,
        id: '[profile]',
      },
    ]
  }, [])

  return {
    navContent,
  }
}
