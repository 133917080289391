import { useAppLocation } from 'app-layered-layout/useAppLocation'
import { usePageActivated } from 'app-layered-layout/usePageActivated'
import { Platform, UserEvent } from 'protobuf/gen/UserEvent'
import { createContext, useContext, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getVisitorId } from 'redux/saga/user'
import { selectIsViewedAdvertisement } from 'redux/selector/app'
import { UAParser } from 'ua-parser-js'
import { getDebugInfo } from 'utils/debugUtil'

let resolve
const analyticsReadyPromise = new Promise((resolve_) => {
  resolve = resolve_
})

const FIRST_LOAD_TIME_KEY = 'flt'

const getFirstLoadTime = () => {
  const t = localStorage.getItem(FIRST_LOAD_TIME_KEY)
  if (t) {
    return new Date(JSON.parse(t))
  }

  const now = Date.now()

  localStorage.setItem(FIRST_LOAD_TIME_KEY, JSON.stringify(now))

  return new Date(now)
}
/**
 * @param {Date} time
 * @returns string
 */
// const formatLogTime = (time) => {
//   // 2022-02-22T00:00:00Z
//   return time.toISOString().slice(0, 19).replace('T', ' ')
// }

const getBasicInfo = () => {
  const parser = new UAParser(navigator.userAgent)
  const parserResults = parser.getResult()

  return /** @type {const} */ ({
    deviceId: getVisitorId(),
    first_open_time: getFirstLoadTime(),
    location: '',
    device_type: parserResults.device.type,
    operating_system: parserResults.os,
    app_version: process.env.REACT_APP_VERSION,
    last_active_time: new Date(),
    phone_model: parserResults.device.model,
    screen_resolution: window.screen?.orientation?.type.startsWith('landscape-')
      ? `${window.screen?.height ?? window.innerHeight}x${window.screen?.width ?? window.innerWidth}`
      : `${window.screen?.width ?? window.innerWidth}x${window.screen?.height ?? window.innerHeight}`,
    network_type: '',
    carrier: '',
  })
}

/**
 * @type {Promise<ReportSystem>[]}
 */
const loadingReportSystems = []

/**
 * @typedef {{
 *   SerialNumber: number
 *   Event: string
 *   Trigger: string
 *   Parameters: string
 *   Value: string
 * }} LogEntry
 */

/**
 * @typedef {{
 *   report: (log: LogEntry, basicInfo: ReturnType<typeof getBasicInfo>) => void
 *   setUser: (user: String) => void
 *   onReady: (location: *) => void
 *   recordPV: (location: *) => void
 * }} ReportSystem
 */

/**
 * @param {Promise<ReportSystem>} reportSystemPromise
 */
const addReportSystem = (reportSystemPromise) => {
  loadingReportSystems.push(
    Promise.resolve(reportSystemPromise).catch((err) => {
      console.error(err)
      return {
        report: () => {},
        setUser: () => {},
        onReady: () => {},
        recordPV: () => {},
      }
    })
  )
}

export const setAnalyticUser = (user) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('[analytics] set user ' + user)
  }
  loadingReportSystems.forEach((p) =>
    p.then((sys) => {
      sys.setUser(user)
    })
  )
}

/**
 * @param {LogEntry} log
 */
export const report = (log) => {
  if (process.env.NODE_ENV === 'development' || getDebugInfo().includes('EVENT_LOG')) {
    console.groupCollapsed(`[analytics] report ${log.Trigger} ${log.Event} ${log.Parameters}: ${log.Value}`)
    console.log(log)
    console.groupEnd()
  }

  const extra = getBasicInfo()

  loadingReportSystems.forEach((p) =>
    p.then((sys) => {
      sys.report(log, extra)
    })
  )
}

/**
 *
 * @param {LogEntry} log
 */
export const useReportAppScreen = (log) => {
  const isViewedAdvertisement = useSelector(selectIsViewedAdvertisement)
  const logged = useRef(false)
  const active = usePageActivated()
  useEffect(() => {
    if (isViewedAdvertisement) {
      if (!active) {
        logged.current = false
      } else if (!logged.current) {
        logged.current = true
        report({
          //   SerialNumber: 1,
          Event: 'app_screen',
          // Trigger: '進入首頁',
          // Parameters: 'page_title',
          // Value: 'cms',
          ...log,
        })
      }
    }
  })
}

const flatten = (obj) => {
  const list = []
  if (obj == null || typeof obj !== 'object') {
    return list
  }
  for (let [k, v] of Object.entries(obj)) {
    if (v != null) {
      if (typeof v === 'object') {
        const subList = flatten(v)
        list.push(...subList.map(([subK, v]) => [`${k}.${subK}`, v]))
      } else {
        list.push([k, v])
      }
    }
  }
  return list
}

/**
 * @param {*} location react router location
 */
export const recordPV = async (location) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('[analytics] recordPV ' + window.location.href, location, flatten({ state: location.state }))
  }

  await getAnalyticReady()
  // record new location here
  loadingReportSystems.forEach((p) =>
    p.then((sys) => {
      sys.recordPV(location)
    })
  )
}
export const useAnalyticsPV = () => {
  // record page view here
  const location = useAppLocation()
  const lastRecorded = useRef()

  useEffect(() => {
    if (lastRecorded.current === location.pathname + location.search + location.hash) {
      return
    }

    lastRecorded.current = location.pathname + location.search + location.hash
    recordPV(location)
  }, [location])
}

export const getAnalyticReady = () => {
  return analyticsReadyPromise
}

export const setAnalyticReady = () => {
  if (process.env.NODE_ENV === 'development') {
    console.log('[analytics] ready')
  }
  resolve()
  loadingReportSystems.forEach((p) =>
    p.then((sys) => {
      sys.onReady()
    })
  )
}

export const useAnalyticsReport = () => {}

if (process.env.REACT_APP_APLUS_ID && process.env.REACT_APP_APLUS_KEY) {
  addReportSystem(
    Promise.resolve({
      setUser: (user) => {
        try {
          // 此处文档以uuid作为示例
          const { aplus_queue } = window
          aplus_queue.push({
            // 设置idtype
            action: 'aplus.setMetaInfo',
            arguments: ['aplus-idtype', 'uuid'], //取值参考见附表1
          })

          aplus_queue.push({
            //设置userid
            action: 'aplus.setMetaInfo',
            // uuid一般是一个32位的随机字符串，当前value仅作为示例参考
            arguments: ['uuid', user],
          })
        } catch (err) {
          console.error(err)
        }
      },
      report: (log) => {},
      onReady: () => {
        try {
          const { aplus_queue } = window
          aplus_queue.push({
            action: 'aplus.setMetaInfo',
            arguments: ['_hold', 'START'],
          })
        } catch (err) {
          console.error(err)
        }
      },
      recordPV: (location) => {
        try {
          const { aplus_queue } = window
          aplus_queue.push({
            action: 'aplus.sendPV',
            arguments: [
              {
                is_auto: false,
                pageUrl: location.pathname + location.search + location.hash,
                title: window.document.title,
              },
            ], // 此处上报的数据暂时在后台没有展示
          })
        } catch (err) {
          console.error(err)
        }
      },
    })
  )
}
async function bufferToBase64(buffer) {
  // use a FileReader to generate a base64 data URI:
  const base64url = await new Promise((r) => {
    const reader = new FileReader()
    reader.onload = () => r(reader.result)
    reader.readAsDataURL(new Blob([buffer]))
  })
  // remove the `data:...;base64,` part from the start
  return base64url.slice(base64url.indexOf(',') + 1)
}

if (process.env.REACT_APP_CLOUDWISE_APM_HOST && process.env.REACT_APP_CLOUDWISE_APM_KEY) {
  const COULDWISE_ANALYTIC_MEMBER_ID = 'member_id'

  let userId
  let markReady
  let readyPromise = new Promise((r) => {
    markReady = r
  })

  const cloudWiseLoaded = new Promise((r) => {
    const handler = () => {
      if (window.CloudWiseUtil) {
        r()
        clearInterval(id)
      }
    }
    const id = setInterval(handler, 1000)
    // run once
    handler()
  })

  addReportSystem(
    Promise.resolve({
      setUser: (user) => {
        localStorage.setItem(COULDWISE_ANALYTIC_MEMBER_ID, user)
        userId = user
      },
      report: async (log, basicInfo) => {
        await readyPromise
        await cloudWiseLoaded
        if (window.CloudWiseUtil) {
          const inst = UserEvent.fromPartial({
            event: log.Event,
            appVersion: basicInfo.app_version,
            deviceId: basicInfo.deviceId,
            memberId: userId,
            parameter: log.Parameters,
            platform: Platform.PLATFORM_WEB,
            timestamp: basicInfo.last_active_time,
            siteId: Number(process.env.REACT_APP_STATION_ID),
            value: log.Value,
          })

          const encoded = UserEvent.encode(inst).finish()
          const formatted = await bufferToBase64(encoded)

          if (process.env.NODE_ENV === 'development') {
            console.groupCollapsed('[cloudwise] report')
            console.group('raw')
            console.log(inst)
            console.groupEnd()
            console.group('formatted')
            console.log(formatted)
            console.groupEnd()
            console.groupEnd()
          }

          const tagsInfo = {
            Event: formatted,
          }
          const type = 'CUSTOM_EVENT'
          window.CloudWiseUtil.setCustomEventInfo(tagsInfo, type)
        }
      },
      onReady: () => {
        markReady()
      },
      recordPV: (_location) => {
        // cloudWise support autoPV
      },
    })
  )
}
const workSectionContext = createContext(
  /** @type {{
  SerialNumber: number
  Event: string
  Trigger: string
  Parameters?: string
  Value?: string
} | null} */ (null)
)
/**
 * @param {{
  SerialNumber: number
  Event: string
  Trigger: string
  Parameters?: string
  Value?: string
  children?: ReactNode
}} param0 
 * @returns 
 */
export const WorkSectionProvider = ({ SerialNumber, Event, Trigger, Parameters, Value, children }) => {
  const value = useMemo(
    () => ({
      SerialNumber,
      Event,
      Trigger,
      ...(Parameters != null ? { Parameters } : {}),
      ...(Value != null ? { Value } : {}),
    }),
    [Event, Parameters, SerialNumber, Trigger, Value]
  )
  return <workSectionContext.Provider value={value}>{children}</workSectionContext.Provider>
}

export const useCurrentWorkSection = () => {
  const ctx = useContext(workSectionContext)
  return ctx
}
